// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  margin-top: 10px;
}

input, select, textarea, button {
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1rem;
}

button {
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
}

button:hover {
  background-color: #45a049;
}

.vertical-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.full-width {
  width: 100%;
}

.mat-form-field {
  margin-bottom: 16px;
}

.sub-form button {
  background-color: #5ea1d8;
  border: none;
  width: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/feedbackforms/feedbackforms.component.scss"],"names":[],"mappings":"AA+BA;EACE,gBAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;AA9BF;;AAiCA;EACE,iBAAA;EACA,gBAAA;AA9BF;;AAiCA;EACE,eAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;AA9BF;;AAiCA;EACE,yBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;AA9BF;;AAiCA;EACE,yBAAA;AA9BF;;AAgCA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;AA7BF;;AA+BA;EACE,WAAA;AA5BF;;AA+BA;EACE,mBAAA;AA5BF;;AA+BE;EACE,yBAAA;EACA,YAAA;EACA,sBAAA;AA5BJ","sourcesContent":["// .feedback-form {\n//     max-width: 600px;\n//     margin: auto;\n//     padding: 20px;\n//     border: 1px solid #ddd;\n//     border-radius: 8px;\n//     background-color: #f9f9f9;\n//   }\n  \n//   .feedback-form h2, h3 {\n//     color: #333;\n//     margin-bottom: 15px;\n//   }\n  \n//   .feedback-form label {\n//     font-weight: bold;\n//     margin-top: 10px;\n//     display: block;\n//   }\n  \n//   .feedback-form textarea {\n//     width: 100%;\n//     resize: none;\n//     margin-top: 5px;\n//     margin-bottom: 15px;\n//   }\n  \n//   .actions {\n//     text-align: center;\n//     margin-top: 20px;\n//   }\nform {\n  max-width: 600px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n}\n\nlabel {\n  font-weight: bold;\n  margin-top: 10px;\n}\n\ninput, select, textarea, button {\n  margin-top: 5px;\n  margin-bottom: 15px;\n  padding: 10px;\n  font-size: 1rem;\n}\n\nbutton {\n  background-color: #4CAF50;\n  color: white;\n  border: none;\n  cursor: pointer;\n  padding: 10px 20px;\n}\n\nbutton:hover {\n  background-color: #45a049;\n}\n.vertical-form {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  width: 100%;\n  max-width: 400px;\n  margin: auto;\n}\n.full-width {\n  width: 100%;\n}\n\n.mat-form-field {\n  margin-bottom: 16px;\n}\n.sub-form{\n  button{\n    background-color: #5ea1d8;\n    border: none;\n    width: auto !important;\n  }\n  \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
