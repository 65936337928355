import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FlowServiceService } from 'src/app/Services/flow-service.service';

@Component({
  selector: 'app-feedbackforms',
  templateUrl: './feedbackforms.component.html',
  styleUrls: ['./feedbackforms.component.scss']
})
export class FeedbackformsComponent implements OnInit {
  userFeedbackForm: FormGroup;
  creatorFeedbackForm: FormGroup;
  didConsultationHappen: boolean = true; // Default to "Yes"
  reasonForNoConsultation: string = '';
  userData: any;
  feedbackData: any;
  feedback: any = {
    partitionKey: '',
    feedbackType: '', // 'User' or 'Creator'
    userName: '',
    creatorName: '',
    consultationId: '',
    didConsultationHappen: true,
    userSatisfaction: null,
    userComments: '',
    userRecommendation: null,
    creatorPreparation: null,
    creatorComments: '',
    creatorFollowUp: null,
    reasonForNoConsultation: ''
  };
  isCreator: boolean;
  constructor(private fb: FormBuilder,
    private flowServiceService: FlowServiceService,
    private router:Router,
    private toast: ToastrService) {
    // this.userFeedbackForm = this.fb.group({
    //   userSatisfaction: [4], // Default satisfaction level
    //   userComments: [''],
    //   userRecommendation: [true],
    // });

    // this.creatorFeedbackForm = this.fb.group({
    //   creatorPreparation: [4], // Default preparation level
    //   creatorComments: [''],
    //   creatorFollowUp: [false],
    // });
  }
  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.feedbackData = JSON.parse(localStorage.getItem("feedbackContentCreatorData"));
    console.log(this.userData);
    console.log(this.feedbackData);
    if (this.userData.emailAddress === this.feedbackData.customerEmailAddress) {
      this.isCreator = true;
     } else {
      this.isCreator = false;
      }
  }

  submitFeedback() {
    if (this.userData.emailAddress === this.feedbackData.customerEmailAddress) {
      this.feedback.feedbackType = 'Creator';
      this.feedback.creatorName = this.userData.firstName;
      this.feedback.userName = this.feedbackData.emailAddress;

    } else {
      this.feedback.feedbackType = 'User';
      this.feedback.creatorName = this.feedbackData.customerFirstName;
      this.feedback.userName = this.userData.emailAddress;
    }
    this.feedback.partitionKey = this.userData.emailAddress;
    this.feedback.consultationId = this.feedbackData.rowKey;
    //   const feedback = {
    //     PartitionKey: this.userFeedbackForm != null ? this.userData.partitionKey : this.feedbackData.partitionKey,
    //     DidConsultationHappen: this.didConsultationHappen,
    //     CreatorComments: this.creatorFeedbackForm.value.creatorComments,
    //     CreatorFollowUp: this.creatorFeedbackForm.value.creatorFollowUp,
    //     CreatorPreparation: this.creatorFeedbackForm.value.creatorPreparation,
    //     userComments: this.userFeedbackForm.value.userComments,
    //     userRecommendation: this.userFeedbackForm.value.userRecommendation,
    //     userSatisfaction: this.userFeedbackForm.value.userSatisfaction,
    //     //  UserFeedback: this.didConsultationHappen === true ? this.userFeedbackForm.value : null,
    //  //   CreatorFeedback: this.didConsultationHappen === true ? this.creatorFeedbackForm.value : null,
    //     ReasonForNoConsultation: this.didConsultationHappen === true ? this.reasonForNoConsultation : null,
    //   };

    console.log('Feedback Submitted:', this.feedback);
    this.flowServiceService.contentCreatorFeedback(this.feedback).subscribe((response) => {
      console.log("response");
      this.toast.success(
        'Succesfully submitted feedback for consultation'
      );
      this.router.navigateByUrl("/my-consultation");
    });
    // Send feedback data to the backend or handle it as needed
  }
}
