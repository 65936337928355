import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-meet-link-dialog',
  templateUrl: './meet-link-dialog.component.html',
  styleUrls: ['./meet-link-dialog.component.scss']
})
export class MeetLinkDialogComponent {
  meetLink: string = '';

  constructor(public dialogRef: MatDialogRef<MeetLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.meetLink = data?.link || ''; // Initialize with existing link if available
  }

  save() {
    this.dialogRef.close(this.meetLink);
  }
}
