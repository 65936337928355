import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { UserserviceService } from "src/app/Services/userservice.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit {
  showNotificationCount: boolean;
  notificationCount: number;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;

  constructor(
    private router: Router,
    
    private userserviceService: UserserviceService
  ) {}

  ngOnInit(): void {
    
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;
    
    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }

    this.getProfileUser();
  }
  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  getProfileUser(): void {
    if (this.userData != null) {
      
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }
}
