import { MatDialog } from "@angular/material/dialog";

import { Component, OnInit } from "@angular/core";
import { UserserviceService } from "src/app/Services/userservice.service";
import { Router } from "@angular/router";
import { DataService } from "src/app/Services/dataservice.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { HttpClient } from "@angular/common/http";
import { ShowFlowImageDialogComponent } from "../show-flow-image-dialog/show-flow-image-dialog.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-items",
  templateUrl: "./items.component.html",
  styleUrls: ["./items.component.scss"],
})
export class ItemsComponent implements OnInit {
  showNotificationCount: boolean;
  notificationCount: number;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  flows: any;
  iname: string;
  itemsRequired: any[];
  quantity: string;
  fileData: File = null;
  noResultReturned: boolean;
  fileName: string;
  Url: string;
  isStepCoverPageURISet: boolean;
  showImage: boolean;
  showVideo: boolean;
  wheretofindit: any;

  constructor(
    private userserviceService: UserserviceService,
    private dataService: DataService,

    private router: Router,
    private http: HttpClient,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {


    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;


    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }

    this.dataService.setFlowByCategorieschangeMessage.subscribe((flowData) => {
      this.flows = flowData;
      this.flows = JSON.parse(localStorage.getItem("flowByCategory"));
    });

    this.itemsRequired = JSON.parse(localStorage.getItem("ItemsRequired"));
    if (this.itemsRequired == null) {
      this.itemsRequired = [];
    }
console.log(this.itemsRequired);
    this.getProfileUser();
  }

  removePicture() {
    this.Url = null;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.itemsRequired,
      event.previousIndex,
      event.currentIndex
    );
  }

  close() {
    localStorage.setItem("ItemsRequired", JSON.stringify(this.itemsRequired));
    this.router.navigate(["/create-flow"]);
  }

  addItems() {
    var editItemsRequiredIndex = localStorage.getItem("editItemsRequiredIndex");

    if (editItemsRequiredIndex != null) {
      this.itemsRequired.splice(parseInt(editItemsRequiredIndex), 1);
    }
    this.itemsRequired.push({
      itemkey: this.iname,
      itemValue: this.quantity,
      url: this.Url,
      wheretofindit: this.wheretofindit,
    });
    

    this.iname = "";
    this.quantity = "";
    this.Url = "";
    this.wheretofindit = "";
    localStorage.setItem("ItemsRequired", JSON.stringify(this.itemsRequired));
    localStorage.removeItem("editItemsRequiredIndex");
  }

  editItems(index, item) {
    this.showImage = true;
    this.iname = item.itemkey;
    this.quantity = item.itemValue;
    this.Url = item.url;
    this.wheretofindit = item.wheretofindit;
    console.log(item.url);
    localStorage.setItem("editItemsRequiredIndex", index);
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  done() {
    localStorage.setItem("ItemsRequired", JSON.stringify(this.itemsRequired));
    this.router.navigate(["/create-flow"]);
  }

  getProfileUser(): void {
    if (this.userData != null) {

      this.showNotificationCount = true;

      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {

            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  openLink(url) {
    url = "https://" + url;
    window.open(url);
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/grid"]);
  }

  remove(index) {
    this.itemsRequired.splice(index, 1);
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  selectFiles(event) {
    if (event.target.files) {
      this.noResultReturned = true;
      //  
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);
      this.fileName = this.fileData.name;
      this.isStepCoverPageURISet = true;
      if (
        this.fileData.name.includes("JPEG") ||
        this.fileData.name.includes("jpeg") ||
        this.fileData.name.includes("png") ||
        this.fileData.name.includes("jpg") ||
        this.fileData.name.includes("PNG") ||
        this.fileData.name.includes("JFIF") ||
        this.fileData.name.includes("jfif") ||
        this.fileData.name.includes("TIFF") ||
        this.fileData.name.includes("tiff") ||
        this.fileData.name.includes("TIF") ||
        this.fileData.name.includes("tif") ||
        this.fileData.name.includes("JPG")
      ) {
        this.http
          .post(
            environment.apiBaseUrl + environment.uploadImageUrl,
            formData
          )
          .subscribe((data) => {
            var mediaUploadStatus = data as any;
            this.showImage = true;
            this.showVideo = false;
            //
            this.noResultReturned = false;

            if (mediaUploadStatus != null) {
              this.Url =
                environment.imageStorageBaseUrl +
                mediaUploadStatus.fileName;
            } else {
              alert(mediaUploadStatus.message);
            }
          });
      } else if (
        this.fileData.name.includes("MP4") ||
        this.fileData.name.includes("mp4") ||
        this.fileData.name.includes("MOV") ||
        this.fileData.name.includes("mov") ||
        this.fileData.name.includes("WMV") ||
        this.fileData.name.includes("wmv") ||
        this.fileData.name.includes("FLV") ||
        this.fileData.name.includes("flv") ||
        this.fileData.name.includes("AVCHD") ||
        this.fileData.name.includes("avchd") ||
        this.fileData.name.includes("WEBM") ||
        this.fileData.name.includes("webm") ||
        this.fileData.name.includes("MKV") ||
        this.fileData.name.includes("mkv") ||
        this.fileData.name.includes("VOB") ||
        this.fileData.name.includes("vob") ||
        this.fileData.name.includes("OGV") ||
        this.fileData.name.includes("ogv") ||
        this.fileData.name.includes("GIFY") ||
        this.fileData.name.includes("gifv") ||
        this.fileData.name.includes("M4P") ||
        this.fileData.name.includes("m4p") ||
        this.fileData.name.includes("M4V") ||
        this.fileData.name.includes("m4v") ||
        this.fileData.name.includes("3GP") ||
        this.fileData.name.includes("3gp") ||
        this.fileData.name.includes("3G2") ||
        this.fileData.name.includes("3g2") ||
        this.fileData.name.includes("AMV") ||
        this.fileData.name.includes("amv") ||
        this.fileData.name.includes("NSV") ||
        this.fileData.name.includes("nsv") ||
        this.fileData.name.includes("OGG") ||
        this.fileData.name.includes("ogg") ||
        this.fileData.name.includes("FLV") ||
        this.fileData.name.includes("flv") ||
        this.fileData.name.includes("F4V") ||
        this.fileData.name.includes("f4v") ||
        this.fileData.name.includes("F4P") ||
        this.fileData.name.includes("f4p") ||
        this.fileData.name.includes("F4A") ||
        this.fileData.name.includes("f4a") ||
        this.fileData.name.includes("F4B") ||
        this.fileData.name.includes("f4b")
      ) {
        this.http
          .post(
            environment.apiBaseUrl + environment.uploadVideoUrl,
            formData
          )
          .subscribe((data) => {
            var mediaUploadStatus = data as any;
            this.showVideo = true;
            this.showImage = false;
            this.noResultReturned = false;
            //
            if (mediaUploadStatus != null) {
              this.Url =
                environment.videoStorageBaseUrl +
                mediaUploadStatus.fileName;
            } else {
              alert(mediaUploadStatus.message);
            }
          });
      }
    }
  }

  openImage(Url) {
    localStorage.setItem("itemsRequiredImageToShow", Url);

    this.dialog.open(ShowFlowImageDialogComponent, {});
  }
}
