// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-primary {
  background-color: #5ea1d8;
  border: none;
}

/* styles.css */
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #5ea1d8;
  color: #fff;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

.generate-btn {
  padding: 10px 20px 10px 10px;
  text-align: right;
}

.table-parent {
  overflow-x: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/consultations/consultations.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,YAAA;AACJ;;AACE,eAAA;AACF;EACE,yBAAA;EACA,WAAA;AAEF;;AACA;EACE,sBAAA;EACA,YAAA;EACA,gBAAA;AAEF;;AACA;EACE,yBAAA;EACA,WAAA;AAEF;;AACA;EACE,yBAAA;AAEF;;AACA;EACE,sBAAA;AAEF;;AACA;EACE,4BAAA;EACA,iBAAA;AAEF;;AAAA;EACE,gBAAA;AAGF","sourcesContent":[".btn-primary {\n    background-color: #5ea1d8;\n    border: none;\n  }\n  /* styles.css */\ntable {\n  border-collapse: collapse;\n  width: 100%;\n}\n\nth, td {\n  border: 1px solid #ddd;\n  padding: 8px;\n  text-align: left;\n}\n\nth {\n  background-color: #5ea1d8;\n  color: #fff;\n}\n\ntr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\ntr:hover {\n  background-color: #ddd;\n}\n\n.generate-btn{\n  padding: 10px 20px 10px 10px;\n  text-align: right;\n}\n.table-parent{\n  overflow-x: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
