import { Component, OnInit } from '@angular/core';
import { FlowQueryModel } from 'src/app/models/flowquerymodel';
import { FlowServiceService } from 'src/app/Services/flow-service.service';

@Component({
  selector: 'app-giftedfunds',
  templateUrl: './giftedfunds.component.html',
  styleUrls: ['./giftedfunds.component.scss']
})
export class GiftedfundsComponent implements OnInit {
  fundData: any;
  noResultReturned: boolean;
  totalFunds: number = 0;
  userData: any;
  filteredFundData: any;
  endDate: any;
  startDate: string | number | Date;
  selectedFilter: any;
  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.getFundDetails();
  }
  constructor(private flowServiceService: FlowServiceService) { }
  applyDateFilter() {
    const now = new Date();
    this.totalFunds = 0;
    switch (this.selectedFilter) {
      case 'today':
        this.filteredFundData = this.fundData.filter(coupon => {
          const receivedDate = new Date(coupon.receivedDate);
          const today = new Date(); // Current date
          return (
            receivedDate.getDate() === today.getDate() &&
            receivedDate.getMonth() === today.getMonth() &&
            receivedDate.getFullYear() === today.getFullYear()
          );
        });

        this.filteredFundData.forEach((flow) => {
          this.totalFunds += flow.fund;
        });
        // this.fundData = this.filteredFundData;
        break;
      case 'lastDay':
        this.filteredFundData = this.fundData.filter(coupon => {
          const receivedDate = new Date(coupon.receivedDate);
          return receivedDate >= new Date(now.setDate(now.getDate() - 1));
        });

        this.filteredFundData.forEach((flow) => {
          this.totalFunds = this.totalFunds + flow.fund
        });
        // this.fundData = this.filteredFundData;
        break;
      case 'lastWeek':
        this.filteredFundData = this.fundData.filter(coupon => {
          const receivedDate = new Date(coupon.receivedDate);
          return receivedDate >= new Date(now.setDate(now.getDate() - 7));
        });
        this.filteredFundData.forEach((flow) => {
          this.totalFunds = this.totalFunds + flow.fund
        });
        // this.fundData = this.filteredFundData;
        break;
      case 'lastMonth':
        this.filteredFundData = this.fundData.filter(coupon => {
          const receivedDate = new Date(coupon.receivedDate);
          return receivedDate >= new Date(now.setMonth(now.getMonth() - 1));
        });
        this.filteredFundData.forEach((flow) => {
          this.totalFunds = this.totalFunds + flow.fund
        });
        // this.fundData = this.filteredFundData;
        break;
      // case 'custom':
      //   this.applyCustomDateFilter();
      //   break;
      default:
        this.filteredFundData = [...this.fundData];

    }
  }

  applyCustomDateFilter() {
    if (this.startDate && this.endDate) {
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);

      this.filteredFundData = this.fundData.filter(coupon => {
        const receivedDate = new Date(coupon.receivedDate);
        return receivedDate >= start && receivedDate <= end;
      });
    }
  }
  getFundDetails(): void {
    this.noResultReturned = true;
    var flowQueryModel = new FlowQueryModel({
      FlowId: this.userData.rowKey
    })
    console.log(flowQueryModel);
    this.flowServiceService
      .getGiftedFundsByCustomerRowKey(flowQueryModel)
      .subscribe((data) => {
        console.log(data);
        this.filteredFundData = data;
        this.fundData=data;
        this.fundData.forEach((flow) => {
          this.totalFunds = this.totalFunds + flow.fund
        }); 
      });
    this.noResultReturned = false;
  }

}
