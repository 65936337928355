
import { Component, OnInit } from "@angular/core";
import { UserserviceService } from "src/app/Services/userservice.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from "src/app/Services/dataservice.service";
import { HttpClient } from "@angular/common/http";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { GetContentService } from "src/app/Services/getcontent.service";
import { MatDialog } from "@angular/material/dialog";
import { SelectionRephraseAIComponent } from "../selection-rephraseAi-dialog/selection-rephraseAi-dialog.component";

@Component({
  selector: "app-steps",
  templateUrl: "./steps.component.html",
  styleUrls: ["./steps.component.scss"],
})
export class StepsComponent implements OnInit {
  showNotificationCount: boolean;
  notificationCount: number;
  fileData: File = null;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  iname: string;
  _steps = [];
  images: any = [];
  allfiles: any = [];
  allfile: any = [];
  videos: any = [];
  flows: any;
  information: string;
  description: string;
  stepToEdit: any;
  showImageorVideo: boolean;
  Url: string;
  posterUrl: string;
  Description: string;
  showVideo: boolean;
  showMedia: string;
  showImage: boolean;
  stepIndex: number;
  isStepCoverPageURISet: boolean;
  fileName: string;
  noResultReturned: boolean;

  constructor(
    private userserviceService: UserserviceService,
    private flowServiceService: FlowServiceService,
    private dataService: DataService,
    private router: Router,
    private dialog:MatDialog,
    private toastManager: ToastrService, 
    private http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private getContentService:GetContentService

  ) {
    this._steps = [];
    this.stepIndex = -1;
    this.stepToEdit = JSON.parse(localStorage.getItem("stepToEdit"));
    if (this.stepToEdit != null) {
      this.Url = this.stepToEdit.stepToEdit.Url;
      this.posterUrl = this.stepToEdit.stepToEdit.posterUrl;
      this.Description = this.stepToEdit.stepToEdit.Description;
      this.showImage = this.stepToEdit.stepToEdit.showImage;
      this.showVideo = this.stepToEdit.stepToEdit.showVideo;
      this.stepIndex = this.stepToEdit.index;
      this.fileName = this.stepToEdit.stepToEdit.stepFileName;
    } else {
      this.Url = "";
      this.Description = "";
      this.stepIndex = -1;
      this.posterUrl = "";
    }

    if (this.Url != "") {
      this.isStepCoverPageURISet = true;
    } else {
      this.isStepCoverPageURISet = false;
    }
  }

  ngOnInit(): void {
    this.flowServiceService.setScrollTop();
    this.information = JSON.parse(localStorage.getItem("information"));
    this.noResultReturned = true;
    // 
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;
    //
    this.noResultReturned = false;
    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }
    this.dataService.setFlowByCategorieschangeMessage.subscribe((flowData) => {
      this.flows = flowData;
      this.flows = JSON.parse(localStorage.getItem("flowByCategory"));
    });


    this._steps = JSON.parse(localStorage.getItem("stepsCoverPageDetails"));
  }
  originalContentTitleText!: string;
  repharaseButtonText: string = "Rephrase text with AI";
  isRephrased: boolean = false;
  isRephraseInProgress: boolean = false;
 
  GoToOriginalText() {
    this.Description = this.originalContentTitleText;
  }

  rephraseDescriptionText() {
    const dialogRef = this.dialog.open(SelectionRephraseAIComponent, {
      width: '400px',
      data: { textToRephrase: this.Description }  // Pass the text to the dialog
    });
    if (!this.isRephrased) {
      this.originalContentTitleText = this.Description;
      console.log(this.Description);
    }
    this.isRephraseInProgress = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the title with the rephrased text and show the selected style  
        this.Description = result.rephrasedText;
        console.log('Selected style:', result.selectedStyle);
        this.isRephrased = true;
        this.isRephraseInProgress = false;
      }
    });
  }
  done() {
    var stepsCoverPage = {
      url: this.Url,
      posterUrl: this.posterUrl,
      description: this.Description,
      stepFileName: this.fileName,
    };


    if (this._steps == null) {
      this._steps = [];
      this._steps.push(stepsCoverPage);
    } else if (this.stepIndex >= 0) {
      var stepValue = this._steps[this.stepIndex];


      if (stepValue != undefined) {
        stepValue.description = this.Description;
        stepValue.url = this.Url;
        this._steps.splice(this.stepIndex, 1, stepValue);
      } else {
        this._steps.push(stepsCoverPage);
      }
    } else {
      this._steps.push(stepsCoverPage);
    }

    localStorage.setItem("stepsCoverPageDetails", JSON.stringify(this._steps));
    this.router.navigate(["/create-flow"], {
      relativeTo: this._activatedRoute,
    });
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/grid"]);
  }
  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  close() {
    localStorage.setItem("stepsCoverPageDetails", JSON.stringify(this._steps));
    this.router.navigate(["/create-flow"]);
  }

  removePicture() {
    this.profilePicture = null;
  }

  getFileName(fileName) {
    return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
  }

  selectFiles(event) {
    if (event.target.files) {
      this.noResultReturned = true;
      //  
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);
      this.fileName = this.fileData.name;
      this.isStepCoverPageURISet = true;
      if (
        this.fileData.name.includes("JPEG") ||
        this.fileData.name.includes("jpeg") ||
        this.fileData.name.includes("png") ||
        this.fileData.name.includes("jpg") ||
        this.fileData.name.includes("PNG") ||
        this.fileData.name.includes("JFIF") ||
        this.fileData.name.includes("jfif") ||
        this.fileData.name.includes("TIFF") ||
        this.fileData.name.includes("tiff") ||
        this.fileData.name.includes("TIF") ||
        this.fileData.name.includes("tif") ||
        this.fileData.name.includes("JPG")
      ) {
        this.http
          .post(
            environment.apiBaseUrl + environment.uploadImageUrl,
            formData
          )
          .subscribe((data) => {
            var mediaUploadStatus = data as any;
            this.showImage = true;
            this.showVideo = false;
            //
            this.noResultReturned = false;
            console.log(data);
            if (mediaUploadStatus != null) {
              this.Url =
                environment.imageStorageBaseUrl +
                mediaUploadStatus.fileName;
            } else {
              alert(mediaUploadStatus.message);
            }
          });
      } else if (
        this.fileData.name.includes("MP4") ||
        this.fileData.name.includes("mp4") ||
        this.fileData.name.includes("MOV") ||
        this.fileData.name.includes("mov") ||
        this.fileData.name.includes("WMV") ||
        this.fileData.name.includes("wmv") ||
        this.fileData.name.includes("FLV") ||
        this.fileData.name.includes("flv") ||
        this.fileData.name.includes("AVCHD") ||
        this.fileData.name.includes("avchd") ||
        this.fileData.name.includes("WEBM") ||
        this.fileData.name.includes("webm") ||
        this.fileData.name.includes("MKV") ||
        this.fileData.name.includes("mkv") ||
        this.fileData.name.includes("VOB") ||
        this.fileData.name.includes("vob") ||
        this.fileData.name.includes("OGV") ||
        this.fileData.name.includes("ogv") ||
        this.fileData.name.includes("GIFY") ||
        this.fileData.name.includes("gifv") ||
        this.fileData.name.includes("M4P") ||
        this.fileData.name.includes("m4p") ||
        this.fileData.name.includes("M4V") ||
        this.fileData.name.includes("m4v") ||
        this.fileData.name.includes("3GP") ||
        this.fileData.name.includes("3gp") ||
        this.fileData.name.includes("3G2") ||
        this.fileData.name.includes("3g2") ||
        this.fileData.name.includes("AMV") ||
        this.fileData.name.includes("amv") ||
        this.fileData.name.includes("NSV") ||
        this.fileData.name.includes("nsv") ||
        this.fileData.name.includes("OGG") ||
        this.fileData.name.includes("ogg") ||
        this.fileData.name.includes("FLV") ||
        this.fileData.name.includes("flv") ||
        this.fileData.name.includes("F4V") ||
        this.fileData.name.includes("f4v") ||
        this.fileData.name.includes("F4P") ||
        this.fileData.name.includes("f4p") ||
        this.fileData.name.includes("F4A") ||
        this.fileData.name.includes("f4a") ||
        this.fileData.name.includes("F4B") ||
        this.fileData.name.includes("f4b")
      ) {
        this.http
          .post(
            environment.apiBaseUrl + environment.uploadVideoUrl,
            formData
          )
          .subscribe((data) => {
            var mediaUploadStatus = data as any;
            console.log(mediaUploadStatus);
            this.showVideo = true;
            this.showImage = false;
            this.noResultReturned = false;
            //
            if (mediaUploadStatus != null) {
              this.Url =
                environment.videoStorageBaseUrl +
                mediaUploadStatus.fileName;

              this.posterUrl =
                environment.imageStorageBaseUrl +
                mediaUploadStatus.posterImageName;

            } else {
              // alert(mediaUploadStatus.message);
            }
          });
      }
    }
  }
}
