import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FlowQueryModel } from 'src/app/models/flowquerymodel';
import { FlowServiceService } from 'src/app/Services/flow-service.service';

@Component({
  selector: 'app-consultations',
  templateUrl: './consultations.component.html',
  styleUrls: ['./consultations.component.scss']
})
export class ConsultationsComponent implements OnInit {
  pastConsulations: any;
  noResultReturned: boolean;
  userData: any;
  filteredConsultations: any[] = [];
  selectedFilter: string = '';
  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.getFundDetails();
  }
  constructor(private flowServiceService: FlowServiceService,
    public router: Router,
    private toast: ToastrService
  ) { }

  // getFundDetails(): void {
  //   this.noResultReturned = true;
  //   var flowQueryModel = new FlowQueryModel({
  //     userEmail: this.userData.partitionKey
  //   });
  //   this.flowServiceService
  //     .getConsultation(flowQueryModel)
  //     .subscribe((data) => {
  //       // data.forEach((flow) => {
  //       //   this.pastConsulations.push(flow);
  //       // });
  //       this.pastConsulations = data
  //       console.log(this.pastConsulations);
  //     });
  //   this.noResultReturned = false;
  // }

  getFundDetails(): void {
    this.noResultReturned = true;
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.partitionKey
    });

    this.flowServiceService.getConsultation(flowQueryModel).subscribe((data) => {
      // If data is available, filter and sort it
      if (data && data.length > 0) {
        // Sort the data in descending order (latest to old)
        this.pastConsulations = data.sort((a, b) => {
          const dateA = new Date(a.createdDateTime); // Adjust this field to the correct date field
          const dateB = new Date(b.createdDateTime); // Adjust this field to the correct date field
          return dateB.getTime() - dateA.getTime(); // Sorting in descending order
        });
        this.filteredConsultations = this.pastConsulations; // Initialize filteredConsultations
        console.log(this.pastConsulations);
      } else {
        this.pastConsulations = [];
        this.noResultReturned = false; // No data found, so set noResultReturned to false
      }
    });
  }
  applyFilter() {
    const today = new Date();
    console.log(this.pastConsulations);
    this.filteredConsultations = this.pastConsulations.filter((data) => {
      // Check if selectedDate is valid
      if (!data.createdDateTime || data.createdDateTime == undefined) {
        return false; // Exclude entries with undefined or null selectedDate
      }
      const selectedDate = new Date(data.createdDateTime);
      switch (this.selectedFilter) {
        case 'today':
          return (
            selectedDate.toDateString() === today.toDateString()
          );
        case 'yesterday':
          const yesterday = new Date();
          yesterday.setDate(today.getDate() - 1);
          return (
            selectedDate.toDateString() === yesterday.toDateString()
          );
        case 'last7days':
          const lastWeek = new Date();
          lastWeek.setDate(today.getDate() - 7);
          return selectedDate >= lastWeek && selectedDate <= today;
        case 'thisMonth':
          return (
            selectedDate.getMonth() === today.getMonth() &&
            selectedDate.getFullYear() === today.getFullYear()
          );
        default:
          return true; // Show all for no filter
      }
    });
    console.log(this.filteredConsultations);
  }

  joinMeet(link: string) {
    if (link) {
      window.open(link, '_blank');
    } else {
      console.error('Invalid link provided');
    }
  }
  submitFeedback(data) {
    if (this.userData.partitionKey === data.customerEmailAddress && data.isCreatorFeedBackSubmitted) {
      this.toast.success("Already submitted the feedback");
    } else if (this.userData.partitionKey != data.customerEmailAddress && data.isUserFeedBackSubmitted) {
      this.toast.success("Already submitted the feedback");
    } else {
      localStorage.setItem("feedbackContentCreatorData", JSON.stringify(data));
      this.router.navigateByUrl("/feedback");
    }
  }
  addToCalendar(data: any): void {
    const startDate = new Date(data.selectedDate); // Assuming selectedDate is in a valid date format
    const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // 1 hour meeting duration (customize as needed)

    const calendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=Consultation+with+${data.customerFirstName}+${data.customerLastName}&dates=${this.formatDate(startDate)}/${this.formatDate(endDate)}&details=${data.reasonForConsultation}&location=${data.preferedMeetPlatform}&sf=true`;

    window.open(calendarUrl, '_blank');
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hour = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    return `${year}${month}${day}T${hour}${minutes}00Z`; // Google Calendar format
  }
  currentDateTime: Date = new Date(); // Current date and time

  isFeedbackAllowed(data: any): boolean {
    const { selectedDate, selectedTime, selectedTimeSlot } = data;

    // Parse `selectedDate` and `selectedTime`
    const meetingDateTime = new Date(selectedDate);
    const [hours, minutes] = selectedTime.split(':').map(Number);
    meetingDateTime.setHours(hours, minutes);

    // Add `selectedTimeSlot` to calculate end time
    const timeSlotParts = selectedTimeSlot.match(/(\d+)\s*hrs\s*(\d+)?\s*mins?/);
    if (timeSlotParts) {
      const additionalHours = parseInt(timeSlotParts[1], 10) || 0;
      const additionalMinutes = parseInt(timeSlotParts[2], 10) || 0;
      meetingDateTime.setHours(meetingDateTime.getHours() + additionalHours);
      meetingDateTime.setMinutes(meetingDateTime.getMinutes() + additionalMinutes);
    }

    // Compare the meeting end time with the current time
    return this.currentDateTime > meetingDateTime;
  }

}
