import { element } from "protractor";
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Router } from "@angular/router";
// import * as firebase from "firebase";

import { Observable, Subject } from "rxjs";
import { debounceTime, map, startWith } from "rxjs/operators";
import { DataService } from "src/app/Services/dataservice.service";
import { EventEmitterService } from "src/app/Services/event-emitter.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { UserserviceService } from "src/app/Services/userservice.service";
import { BundleService } from "src/app/Services/bundleservice.service";
import { TitleandmetaserviceService } from "src/app/Services/titleandmetaservice.service";
import { BundledialogComponent } from "../bundledialog/bundledialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Location } from "@angular/common";
import { FlowQueryModel } from "src/app/models/flowquerymodel";
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  userDisplayName: any;
  userData: any;
  currentImage: any;
  previousIndex: any;
  showImg = false;
  arrow = false;
  itemCount = 0;
  scrHeight: any;
  scrWidth: any;
  carouselOptions: any;
  profilePicture: string;
  name: string;
  email: string;
  website: string;
  phone: string;
  info: string;
  uname: string;
  flowData: any;
  categories: any[] = [];
  showDefaultImage: boolean;
  isMobileView: boolean;
  showNotificationCount: boolean;
  notificationCount: number;
  notificationtitle: any;
  notificationbody: any;
  notificationsarray: any[] = [];
  notificationscount: number;
  ProfilePic: any;
  input: string = "";
  noResultReturned: boolean;
  noAllResultReturned: boolean;
  itemsRequired: boolean;
  isSearchByFlows: boolean;
  isSearchByUsers: boolean;
  isSearchByBundle: boolean;

  automobilesbgColor: any;
  automobilesColor: any;

  adventuresbgColor: any;
  adventuresColor: any;
  artandCraftbgColor: any;
  artandCraftColor: any;

  beautyColor: any;
  beautybgColor: any;

  entertainmentColor: any;
  entertainmentbgColor: any;

  fashionbgColor: any;
  fashionColor: any;

  fitnessbgColor: any;
  fitnessColor: any;

  gamesbgColor: any;
  gamesColor: any;
  homeandbgGardenColor: any;
  homeandGardenColor: any;

  metalbgworksColor: any;
  metalworksColor: any;

  photographybgColor: any;
  photographyColor: any;

  recipebgColor: any;
  recipeColor: any;

  technologybgColor: any;
  technologyColor: any;

  woodworksbgColor: any;
  woodworksColor: any;

  userColor: any;
  userbgColor: any;

  userDisplayEmail: any;
  allFlowsbgColor: any;
  allFlowsColor: any;

  itemsRequiredbgColor: any;
  itemsRequiredColor: any;
  selectedcategory: any;

  myControl = new UntypedFormControl();
  options: string[] = [];
  flows: any[] = [];
  tempFlows: any[] = [];
  allUsers: any;
  tempUsers: any;

  private scrollSubject = new Subject<MouseEvent>();

  temporaryFlows: any = [];
  infiniteScrollCount: number;
  getNotificationUrl: string;
  filteredOptions: Observable<string[]>;
  bundlesbgColor: string;
  bundlesColor: string;

  private searchBundleTerms = new Subject<string>();
  private searchItemsRequiredTerms = new Subject<string>();
  private searchTerms = new Subject<string>();
  private searchTermsUsers = new Subject<string>();
  account_circle: string;
  add_circle_outlinebgColor: string;
  add_circle_outlineColor: string;
  add_circle_outlineSize: string;

  constructor(
    public dialog: MatDialog,
    private location: Location,
    private flowServiceService: FlowServiceService,
    private router: Router,
    private dataService: DataService,

    private userserviceService: UserserviceService,
    private eventEmitterService: EventEmitterService,
    private changeDetectorRef: ChangeDetectorRef,
    private ref: ChangeDetectorRef,
    private bundleService: BundleService,
    private titleandmetaserviceService: TitleandmetaserviceService
  ) {
    this.titleandmetaserviceService.setTitle("Home Page");
    this.getNotificationUrl = "v1/Notification/GetUserNotifications";
    this.categories = [];
    this.getScreenSize();
    this.eventEmitterService.callEvent();
    this.eventEmitterService.setHomePageIconColorEvent("search");
    this.itemsRequired = false;
    this.isSearchByFlows = true;
    this.isSearchByUsers = false;
    this.isSearchByBundle = false;
    this.selectedcategory = "All";
    this.userDisplayEmail = "";
    this.infiniteScrollCount = 4;
    this.account_circle = "account_circle";
    if (this.eventEmitterService.setProfilePictureCompleted === undefined) {
      this.eventEmitterService.setProfilePictureCompleted =
        this.eventEmitterService.setProfilePictureFunction.subscribe(
          (profilePicture: string) => {
            this.showDefaultImage = false;
            this.profilePicture = profilePicture;
          }
        );
    }

    this.searchTerms
      .pipe(debounceTime(2000)) // Adjust the debounce time as needed
      .subscribe(() => {
        // Call your search function here
        localStorage.removeItem("pageIndex");
        localStorage.setItem("pageIndex", "0");
        this.performSearch(0);
      });

    this.searchTermsUsers
      .pipe(debounceTime(2000)) // Adjust the debounce time as needed
      .subscribe(() => {
        // Call your search function here
        this.performUsersSearch(0);
      });

    this.searchBundleTerms
      .pipe(debounceTime(2000)) // Adjust the debounce time as needed
      .subscribe(() => {
        // Call your search function here
        localStorage.removeItem("pageIndex");
        localStorage.setItem("pageIndex", "0");
        this.performBundleSearch(0);
      });

    this.searchItemsRequiredTerms
      .pipe(debounceTime(2000)) // Adjust the debounce time as needed
      .subscribe(() => {
        // Call your search function here
        this.performItemsRequiredSearch(0);
      });
  }

  performItemsRequiredSearch(pageIndex) {
    this.noResultReturned = true;
    this.noAllResultReturned = true;
    this.email = "";

    if (this.userData != null) {
      this.email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      category: this.selectedcategory,
      searchString: this.input,
      userEmail: this.email,
      pageIndex: pageIndex
    });

    this.flowServiceService
      .searchInItemsRequired(flowQueryModel)
      .subscribe((data) => {
        this.tempFlows = [];
        this.noResultReturned = false;
        this.noAllResultReturned = false;
        if (data.flows != null && data.flows != undefined) {
          data.flows.forEach((element) => {
            this.tempFlows.push(element);
          });
        }
      });
  }

  performUsersSearch(pageIndex) {
    this.noResultReturned = true;
    this.noAllResultReturned = true;
    this.allUsers = [];
    this.tempUsers = [];
    var email = "";

    if (this.userData != null && this.userData != undefined) {
      email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      category: this.selectedcategory,
      searchString: this.input,
      userEmail: this.email,
      pageIndex: pageIndex,
    });

    this.userserviceService
      .searchInUsers(flowQueryModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        this.noAllResultReturned = false;
        if (data.allUsers.length > 0) {
          this.tempUsers = [];
          this.allUsers = [];
          data.allUsers.forEach((element) => {
            const user = {
              user: element,
              showFollow: false,
              followButtonText: element.followingText,
            };
            this.allUsers.push(user);
          });

          this.tempUsers = this.allUsers;
          this.tempUsers.forEach((element) => {
            if (element.followButtonText === "Following") {
              element.followButtonText = "Following";
              element.followingcolor = "black";
              element.followingbgcolor = "white";
            } else {
              element.followButtonText = "Follow";
              element.followingcolor = "white";
              element.followingbgcolor = "#5ea1d8";
            }
          });
        }
      });
  }

  performSearch(pageIndex) {
    this.noResultReturned = true;
    this.noAllResultReturned = true;
    this.email = "";

    if (this.userData != null) {
      this.email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      category: this.selectedcategory,
      searchString: this.input,
      userEmail: this.email,
      pageIndex: pageIndex
    });

    this.flowServiceService
      .searchInFlows(flowQueryModel)
      .subscribe((data) => {
        this.tempFlows = [];
        this.noResultReturned = false;
        this.noAllResultReturned = false;
        if (data.flows != null && data.flows != undefined) {
          data.flows.forEach((element) => {
            this.tempFlows.push(element);
          });
        }
      });
  }

  performBundleSearch(pageIndex) {
    console.log("performBundleSearch");
    this.noResultReturned = true;
    this.noAllResultReturned = true;
    this.email = "";

    if (this.userData != null) {
      this.email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      category: this.selectedcategory,
      searchString: this.input,
      userEmail: this.email,
      pageIndex: pageIndex
    });

    this.flowServiceService
      .searchInBundles(flowQueryModel)
      .subscribe((data) => {
        this.tempFlows = [];
        this.noResultReturned = false;
        this.noAllResultReturned = false;
        if (data.flows != null && data.flows != undefined) {
          data.flows.forEach((element) => {
            this.tempFlows.push(element);
          });
        }
      });
  }

  searchBundles() {
    this.searchBundleTerms.next(this.input);
  }

  ngOnInit(): void {
    this.flowServiceService.setScrollTop();
    // this.eventEmitterService.setNotificationCompleted =
    //   this.eventEmitterService.setNotificationFunction.subscribe(
    //     (notificationModelArray: any) => {
    //       this.notificationsarray = [];
    //       this.showNotificationCount = true;
    //       const notifications = JSON.parse(notificationModelArray);
    //       notifications.forEach((element) => {
    //         this.notificationsarray.push(element.notification);
    //       });
    //       this.notificationscount = this.notificationsarray.length;
    //       this.ref.detectChanges();
    //     }
    //   );
    //

    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    if (this.userData != null) {
      this.eventEmitterService.setUserProfileCreateIconClickedEvent("search");
      this.userDisplayName = this.userData.firstName;
      this.userDisplayEmail = this.userData.emailAddress;
      this.getnotification();
      this.getProfileUser();
    }

    this.getFlows();
    //this.getProfileUser();
    this.eventEmitterService.callEvent();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );

    this.setallgray("All");

    // if (this.eventEmitterService.setNotificationCompleted === undefined) {
    //   this.eventEmitterService.setNotificationCompleted =
    //     this.eventEmitterService.setNotificationFunction.subscribe(
    //       (notificationModelArray: any) => {
    //         this.showNotificationCount = true;
    //         this.notificationscount = notificationModelArray.length;
    //         this.notificationsarray = notificationModelArray;
    //       }
    //     );
    // }

    this.scrollSubject.pipe(debounceTime(1000))
      .subscribe({
        next: event => {
          this.performScroll(event);
        },
        error: error => {
          console.error('Error in scroll subscription:', error);
        },
      });
  }

  performScroll(event: MouseEvent) {
    this.delayedServiceCall();
  }

  getProfileUser(): void {
    if (this.userData != null) {
      //
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
          //
          this.getnotification();
        });
    }
  }

  // avatarClicked(notification: any, source: any) {
  //   if (source == "flow clicked") {
  //     localStorage.setItem("notificationflowId", notification.rowKey);
  //     localStorage.setItem("flow", null);
  //     this.router.navigate(["/view/" + notification.rowKey]);
  //   } else if (source == "profile clicked") {
  //   }
  // }

  avatarClicked(notification: any, source: any) {
    console.log(notification);
    if (notification.ContentType == "Flow") {
      localStorage.setItem("notificationflowId", notification.FlowId);
      localStorage.setItem("flow", null);
      this.router.navigate(["/view/" + notification.FlowId]);
    } else if (notification.ContentType == "Bundle") {
      this.router.navigate(["/viewbundle/" + notification.FlowId]);
    } else if (source == "User Following") {
      if (this.userData.emailAddress === notification.NotifierUserEmail) {
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }

      this.dataService.flowByCategorieschangeMessage(notification);
    }
  }
  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.resize();
  }

  resize() {
    this.carouselOptions = {
      margin: 0,
      nav: true,
      dots: false,
      pagination: false,
      responsiveClass: true,
      responsive: {
        0: {
          items: 2,
          nav: true,
        },
        460: {
          items: 2,
          nav: true,
        },
        550: {
          items: 3,
          nav: true,
        },
        600: {
          items: 3,
          nav: true,
        },
        700: {
          items: 3,
          nav: true,
        },
        768: {
          items: 4,
          nav: true,
        },
        800: {
          items: 4,
          nav: true,
        },
        900: {
          items: 5,
          nav: true,
        },
        1024: {
          items: 5,
          nav: true,
          loop: false,
        },

        1100: {
          items: 8,
          nav: true,
          loop: false,
        },

        1200: {
          items: 8,
          nav: true,
          loop: false,
        },
        1300: {
          items: 8,
          nav: true,
          loop: false,
        },
        1400: {
          items: 8,
          nav: true,
          loop: false,
        },
        1500: {
          items: 8,
          nav: true,
          loop: false,
        },
      },
    };
  }

  onResize(event) {
    this.resize();

    const width = window.innerWidth || document.body.clientWidth;

    if (width <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  flowByCategory(itemCategory) {
    this.tempFlows = [];
    this.tempUsers = [];
    this.itemsRequired = false;
    this.isSearchByFlows = false;
    this.isSearchByUsers = false;
    this.isSearchByBundle = false;
    this.selectedcategory = itemCategory;
    this.setallgray(itemCategory);

    if (itemCategory == "All" || itemCategory == "Items Required") {
      this.noResultReturned = true;
      //this.tempFlows = this.flows;
      if (itemCategory == "Items Required") {
        this.itemsRequired = true;
        this.searchItemsRequired(0);
      } else {
        this.isSearchByFlows = true;
        this.searchFlows(0);
      }
    } else {
      this.isSearchByFlows = true;
      //
      this.tempFlows = [];
      localStorage.removeItem("categoryPageIndex");
      localStorage.setItem("categoryPageIndex", "0");
      this.noResultReturned = true;

      if (this.input != "" && this.input != undefined) {
        this.searchFlows(0);
      } else {
        this.email = "";

        if (this.userData != null) {
          this.email = this.userData.emailAddress;
        }
        this.flowServiceService
          .getFlowsByInputCategory(this.email, itemCategory, "0")
          .subscribe((data) => {
            this.noResultReturned = false;
            data.Flows.forEach((element) => {
              this.tempFlows.push(element);
            });
            // 
          });

        //this.searchTempFlows();
      }
    }
  }

  filteredUsers() { }

  flowsbyItemsRequired(pageIndex) {
    this.email = "";

    if (this.userData != null) {
      this.email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      category: this.selectedcategory,
      searchString: this.input,
      userEmail: this.email,
      pageIndex: pageIndex,
    });

    this.flowServiceService
      .getFlowsByItemsRequired(flowQueryModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        this.noAllResultReturned = false;
        if (data.flows.length > 0) {
          data.flows.forEach((element) => {
            this.tempFlows.push(element);
          });
        }
      });
  }

  searchItemsRequired(pageIndex) {
    let flows = [];

    this.noResultReturned = true;

    if (this.input !== '' && this.input !== undefined) {
      this.performItemsRequiredSearch(pageIndex);
    } else {
      this.flowsbyItemsRequired(pageIndex);
    }
  }

  searchedItemsRequiredFlows() {
    this.searchItemsRequiredTerms.next(this.input);
  }

  searchedFlows() {
    this.searchTerms.next(this.input);
  }

  openDialog() {
    if (this.userData != null) {
      this.add_circle_outlinebgColor = "white";
      this.add_circle_outlineColor = "#5ea1d8 !important";
      this.add_circle_outlineSize = "30px";
      localStorage.removeItem("ItemsRequired");
      localStorage.removeItem("flowCoverPageDetails");
      localStorage.removeItem("SupportingDocumentDocuments");
      localStorage.removeItem("SupportingDocumentHyperLinksRequired");
      localStorage.removeItem("stepsCoverPageDetails");
      localStorage.removeItem("draftWord");
      localStorage.removeItem("editWord");
      const dialogRef = this.dialog.open(BundledialogComponent, {
        panelClass: "dialoguebox",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "bundle") {
          localStorage.setItem(
            "navigatebackfromcreatebundle",
            this.location.path()
          );
          localStorage.removeItem("createbundledetails");
          localStorage.removeItem("addbundleselectedflows");
          this.router.navigate(["/createbundle"]);
        } else if (result === "flow") {
          localStorage.setItem(
            "navigatebackfromcreateflow",
            this.location.path()
          );
          this.router.navigate(["/create-flow"]);
        } else {
          this.eventEmitterService.setUserProfileCreateIconClickedEvent("");
          this.add_circle_outlinebgColor = "white";
          this.add_circle_outlineColor = "#212427 !important";//window.location.reload();
        }

      });
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  searchFlows(pageIndex) {
    this.noAllResultReturned = true;
    this.noResultReturned = true;
    this.email = "";

    if (this.userData != null) {
      this.email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      category: this.selectedcategory,
      searchString: this.input,
      userEmail: this.email,
      pageIndex: pageIndex
    });

    if (this.input !== '' && this.input !== undefined) {
      this.performSearch(pageIndex);
    } else {
      this.flowServiceService
        .getSearchApprovedFlowsByFlowQueryModel(flowQueryModel)
        .subscribe((data) => {
          this.noResultReturned = false;
          this.noAllResultReturned = false;
          if (data.flows != null && data.flows != undefined) {
            data.flows.forEach((element) => {
              this.tempFlows.push(element);
            });
          }
          this.account_circle = "account_circle";
        });
    }
  }

  searchUsers(category) {
    this.searchTermsUsers.next(category);
  }

  setallgray(selectedCategory) {
    this.allFlowsbgColor = "white";
    this.allFlowsColor = "gray";

    this.userbgColor = "white";
    this.userColor = "gray";

    // this.automobilesbgColor = "white";
    // this.automobilesColor = "gray";

    // this.adventuresbgColor = "white";
    // this.adventuresColor = "gray";

    // this.artandCraftbgColor = "white";
    // this.artandCraftColor = "gray";

    // this.beautybgColor = "white";
    // this.beautyColor = "gray";

    // this.fashionbgColor = "white";
    // this.fashionColor = "gray";

    // this.fitnessbgColor = "white";
    // this.fitnessColor = "gray";

    // this.homeandbgGardenColor = "white";
    // this.homeandGardenColor = "gray";

    // this.metalbgworksColor = "white";
    // this.metalworksColor = "gray";

    // this.photographybgColor = "white";
    // this.photographyColor = "gray";

    // this.recipebgColor = "white";
    // this.recipeColor = "gray";

    // this.technologybgColor = "white";
    // this.technologyColor = "gray";

    // this.woodworksbgColor = "white";
    // this.woodworksColor = "gray";

    this.itemsRequiredbgColor = "white";
    this.itemsRequiredColor = "gray";

    this.bundlesbgColor = "white";
    this.bundlesColor = "gray";

    // this.gamesbgColor = "white";
    // this.gamesColor = "gray";

    // this.entertainmentbgColor = "white";
    // this.entertainmentColor = "gray";

    this.setSelectedCategoryToBlack(selectedCategory);
  }

  setSelectedCategoryToBlack(selectedCategory) {
    this.itemsRequired = false;
    switch (selectedCategory) {
      case "All":
        this.allFlowsbgColor = "gray";
        this.allFlowsColor = "white";
        break;
      case "Users":
        this.userbgColor = "gray";
        this.userColor = "white";
        break;
      // case "Adventures":
      //   this.adventuresbgColor = "gray";
      //   this.adventuresColor = "white";
      //   break;
      // case "Art & Craft":
      //   this.artandCraftbgColor = "gray";
      //   this.artandCraftColor = "white";
      //   break;
      // case "Automobiles":
      //   this.automobilesbgColor = "gray";
      //   this.automobilesColor = "white";
      //   break;
      // case "Beauty":
      //   this.beautybgColor = "gray";
      //   this.beautyColor = "white";
      //   break;
      // case "Fashion":
      //   this.fashionbgColor = "gray";
      //   this.fashionColor = "white";
      //   break;
      // case "Fitness":
      //   this.fitnessColor = "white";
      //   this.fitnessbgColor = "gray";
      //   break;
      // case "Home Improvements":
      //   this.homeandGardenColor = "white";
      //   this.homeandbgGardenColor = "gray";
      //   break;
      // case "Media Production":
      //   this.photographyColor = "white";
      //   this.photographybgColor = "gray";
      //   break;
      // case "Beauty":
      //   this.beautybgColor = "gray";
      //   this.beautyColor = "white";
      //   break;
      // case "Entertainment":
      //   this.entertainmentbgColor = "gray";
      //   this.entertainmentColor = "white";
      //   break;
      // case "Games":
      //   this.gamesbgColor = "gray";
      //   this.gamesColor = "white";
      //   break;
      // case "Metalworks":
      //   this.metalbgworksColor = "gray";
      //   this.metalworksColor = "white";
      //   break;
      // case "Recipe":
      //   this.recipebgColor = "gray";
      //   this.recipeColor = "white";
      //   break;
      // case "Technology":
      //   this.technologybgColor = "gray";
      //   this.technologyColor = "white";
      //   break;
      // case "Woodworks":
      //   this.woodworksColor = "white";
      //   this.woodworksbgColor = "gray";
      //   break;
      case "Items Required":
        this.itemsRequired = true;
        this.itemsRequiredColor = "white";
        this.itemsRequiredbgColor = "gray";
        break;
      case "Bundles":
        this.bundlesColor = "white";
        this.bundlesbgColor = "gray";
        break;
    }
  }

  viewUserProfile(selectedFlow) {
    if (this.userData != null) {
      if (
        this.userData.emailAddress === selectedFlow.customerEmail ||
        this.userData.emailAddress === selectedFlow.emailAddress
      ) {
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }
      this.dataService.flowByCategorieschangeMessage(selectedFlow);
    } else {
      // this.dataService.flowByCategorieschangeMessage(selectedFlow);
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);  
    }
  }

  CheckIsUserFollowing(user) {
    if (this.userData != null) {
      if (this.userData.emailAddress === user.user.emailAddress) {
        user.showFollow = false;
      } else {
        user.showFollow = true;

        const flowModel = {
          CustomerEmail: user.user.emailAddress,
          UserEmailAddress: this.userData.emailAddress,
        };

        this.flowServiceService
          .CheckIsUserFollowing(flowModel)
          .subscribe((data) => {
            if (data.MessageText === "Following") {
              user.followButtonText = "Following";
              user.followingcolor = "black";
              user.followingbgcolor = "white";
            } else {
              user.followButtonText = "Follow";
              user.followingcolor = "white";
              user.followingbgcolor = "#5ea1d8";
            }
          });
      }
    }
  }

  followUser(selectedUser) {
    if (this.userData != null) {
      //

      const flowModel = {
        UserEmail: selectedUser.emailAddress,
        FollowerEmail: this.userData.emailAddress,
      };

      this.flowServiceService.FollowUser(flowModel).subscribe((data) => {
        let user = this.tempUsers.filter(
          (u) => u.user.emailAddress == selectedUser.emailAddress
        )[0];

        if (data.isFollowing === "following") {
          user.followButtonText = "Following";
          user.followingcolor = "black";
          user.followingbgcolor = "white";
        } else {
          user.followButtonText = "Follow";
          user.followingcolor = "white";
          user.followingbgcolor = "#5ea1d8";
        }
        this.changeDetectorRef.detectChanges();

      });
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  getAllUsers(itemcategory) {
    this.itemsRequired = false;
    localStorage.removeItem("searchuserpageIndex");
    localStorage.setItem("searchuserpageIndex", "0");
    this.selectedcategory = itemcategory;

    if (itemcategory != "All") {
      this.isSearchByFlows = false;
      this.isSearchByUsers = true;
      this.tempFlows = [];
      this.isSearchByBundle = false;
    }

    this.setallgray(itemcategory);
    //this.tempFlows = [];
    //
    this.allUsers = [];
    this.tempUsers = [];
    this.getPaginatedUsers(undefined, "0");
  }

  getPaginatedUsers(infiniteScroll, pageIndex) {
    var email = "";

    if (this.userData != null && this.userData != undefined) {
      email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      category: this.selectedcategory,
      searchString: this.input,
      userEmail: this.email,
      pageIndex: pageIndex,
    });

    if (this.input !== '' && this.input !== undefined) {
      this.performUsersSearch(pageIndex);
    }
    else {

      this.userserviceService
        .getAllUsers(flowQueryModel)
        .subscribe((data) => {

          if (data.allUsers.length > 0) {
            this.tempUsers = [];
            data.allUsers.forEach((element) => {
              const user = {
                user: element,
                showFollow: false,
                followButtonText: element.followingText,
              };
              this.allUsers.push(user);
            });
            this.tempUsers = this.allUsers;
            this.tempUsers.forEach((element) => {
              if (element.followButtonText === "Following") {
                element.followButtonText = "Following";
                element.followingcolor = "black";
                element.followingbgcolor = "white";
              } else {
                element.followButtonText = "Follow";
                element.followingcolor = "white";
                element.followingbgcolor = "#5ea1d8";
              }
            });
          }
        });
    }
  }

  getFlows(): void {
    this.flows = [];
    this.noResultReturned = true;
    localStorage.removeItem("pageIndex");
    localStorage.setItem("pageIndex", "0");
    this.searchFlows(0);
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  onScroll(event: MouseEvent) {
    this.noResultReturned = true;
    this.scrollSubject.next(event);
  }

  delayedServiceCall() {

    this.email = "";

    if (this.userData != null) {
      this.email = this.userData.emailAddress;
    }
    console.log("scroll");
    if (this.selectedcategory == "All") {
      console.log("scroll");
      var pageIndex = localStorage.getItem("pageIndex");
      pageIndex = (parseInt(pageIndex) + 1).toString();
      localStorage.setItem("pageIndex", pageIndex);
      this.searchFlows(pageIndex);
    } else {
      var pageIndex = localStorage.getItem("categoryPageIndex");
      pageIndex = (parseInt(pageIndex) + 1).toString();
      localStorage.setItem("categoryPageIndex", pageIndex);
      if (
        this.selectedcategory == "Items Required" &&
        this.input != "" &&
        this.input != undefined
      ) {
        console.log("scroll");
        var pageIndex = localStorage.getItem("pageIndex");
        pageIndex = (parseInt(pageIndex) + 1).toString();
        localStorage.setItem("pageIndex", pageIndex);
        this.itemsRequired = true;
        this.performItemsRequiredSearch(pageIndex);
      } else if (
        this.selectedcategory == "Bundles") {
        this.searchBundleFlows(parseInt(pageIndex));
      } else if (this.selectedcategory == "Users") {
        this.noResultReturned = false;
        pageIndex = localStorage.getItem("searchuserpageIndex");
        pageIndex = (parseInt(pageIndex) + 1).toString();
        localStorage.setItem("searchuserpageIndex", pageIndex);
        this.getPaginatedUsers(undefined, pageIndex);
      } else {
        this.flowsbyItemsRequired(pageIndex);
      }
    }
  }

  searchBundleFlows(pageIndex: number) {
    this.noResultReturned = true;
    this.noAllResultReturned = true;
    this.email = "";

    if (this.userData != null) {
      this.email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      category: this.selectedcategory,
      searchString: this.input,
      userEmail: this.email,
      pageIndex: pageIndex
    });

    if (this.input !== '' && this.input !== undefined) {
      this.performBundleSearch(pageIndex);
    } else {
      this.flowServiceService
        .getSearchApprovedBundleByBundleQueryModel(flowQueryModel)
        .subscribe((data) => {
          this.noResultReturned = false;
          this.noAllResultReturned = false;
          if (data.flows != null && data.flows != undefined) {
            data.flows.forEach((element) => {
              this.tempFlows.push(element);
            });
          }

        });
    }
  }

  flowClicked(flow) {
    localStorage.setItem("flow", JSON.stringify(flow));
    localStorage.setItem("notificationflowId", null);
    localStorage.setItem("navigateTo", "search");
    if (this.selectedcategory == "Bundles") {
      this.router.navigate(["/viewbundle/" + flow.rowKey]);
    } else {
      this.router.navigate(["/view/" + flow.rowKey.toString()]);
    }
  }

  showImage(itemCategory, item, index) {
    itemCategory.flowModels.forEach((element) => {
      if (element.arrow !== undefined) {
        element.arrow = false;
      }
    });
    if (!itemCategory.showImg || index !== this.previousIndex) {
      this.currentImage = item.Url;
      this.previousIndex = index;
      itemCategory.showImg = true;
      item.arrow = true;
    } else {
      itemCategory.showImg = false;
      item.arrow = false;
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  getnotification() { 
    this.email = "";

    if (this.userData != null) {
      this.email = this.userData.emailAddress;
    }
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      pageIndex: 0,
    });
    
    this.flowServiceService
      .getNotifications(flowQueryModel).subscribe((data) => {
      if (data != null) {
        data.forEach((element) => {
         // console.log(element);
          var notification = {
            notification: {
              ProfilePicUrl: element.notifierProfilePicUrl,
              NotifierUserName: element.notifierUserName,
              Message: element.message,
              FlowUrl: element.flowUrl,
              FlowId: element.rowKey,
              ContentType :element.contentType,
              NotifierUserEmail: element.notifierUserEmail,
            },
          };
          //console.log(notification);
          this.notificationsarray.push(notification); 
        });
      }
    });
    console.log(this.notificationsarray);
    this.notificationscount = this.notificationsarray.length;
    console.log(this.notificationscount);
    this.showNotificationCount = true;
  }

  getAllBundles(itemCategory) {
    this.tempFlows = [];
    this.tempUsers = [];
    this.isSearchByBundle = true;
    this.itemsRequired = false;
    this.isSearchByFlows = false;
    this.isSearchByUsers = false;
    this.selectedcategory = itemCategory;
    this.setallgray(itemCategory);
    localStorage.removeItem("categoryPageIndex");
    localStorage.setItem("categoryPageIndex", "0");
    this.noResultReturned = true;
    this.searchBundleFlows(0);
  }

  getBundles(itemCategory, pageIndex) {
    this.bundleService
      .getBundles("", itemCategory, pageIndex)
      .subscribe((data) => {
        this.noResultReturned = false;
        data.Bundles.forEach((element) => {
          this.tempFlows.push(element);
        });
      });
  }
}
