
import { Component, OnInit } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";

import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  FormControlName,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { UserserviceService } from "src/app/Services/userservice.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { Endpoint } from "src/app/shared/endpoints";

@Component({
  selector: "app-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.scss"],
})
export class ForgetPasswordComponent implements OnInit {
  showNotificationCount: boolean;
  notificationCount: number;
  EmailAddress: string;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  noResultReturned: boolean;
  showMessage: boolean;
  userEmailTaken: boolean;

  constructor(
    public formbuilder: UntypedFormBuilder,
    
    private router: Router,
    private toastr: ToastrService,
    private userserviceService: UserserviceService
  ) {}
  forgetPasswordForm = new UntypedFormGroup({
    EmailAddress: new UntypedFormControl("", [Validators.required, Validators.email]),
    Password: new UntypedFormControl("", Validators.required),
  });

  ngOnInit(): void {
    
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;
    
    if (this.userData != null) {
      this.userDisplayName = this.userData.FirstName;
    }

    this.forgetPasswordForm = this.formbuilder.group({
      EmailAddress: new UntypedFormControl("", [
        Validators.required,
        Validators.email,
      ]),
    });

    this.getProfileUser();
  }

  IsUserEmailTaken() {
    if (this.EmailAddress != "" && this.EmailAddress != null) {
      this.noResultReturned = true;
      //
      const loginModel = {
        UserEmail: this.EmailAddress, 
      };
      this.userserviceService.checkUserWithEmailAddressExists(loginModel)
        .subscribe((data) => {
          if (data == true) {
            this.userEmailTaken = true;
          }
          if (data == false) {
            this.userEmailTaken = false;
          }
          this.noResultReturned = false; 
          //
        });
    }
  }

  submit() {
    this.IsUserEmailTaken();
    if ( this.EmailAddress== null || undefined) 
    {
      this.showMessage = true; 
      this.toastr.warning(" Add email address to get activation code .");
    }
    else if(this.userEmailTaken){
      this.showMessage = false; 
      this.toastr.success("Activation Code Sent Sucessfully");
    this.noResultReturned = true;
    const userModel = {
      UserEmail: this.EmailAddress,
    };

  
    this.userserviceService
      .changeOldPassword(Endpoint.forgetPassword, userModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        localStorage.setItem("userEmail", JSON.stringify(this.EmailAddress));
        this.router.navigate(["/change-password"]);
      });
    }else{
      this.showMessage = true; 
      this.toastr.warning("Email address does not exist please register to continue on next steps.");
    }
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  getProfileUser(): void {
    if (this.userData != null) {
      
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/grid"]);
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }
  showToastForDelete(message) {
    this.toastr.success(message);
  }
  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
  }
}
