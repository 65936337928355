import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FlowQueryModel, UserQueryModel } from 'src/app/models/flowquerymodel';
import { FlowServiceService } from 'src/app/Services/flow-service.service';
import { UserserviceService } from 'src/app/Services/userservice.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bookconsultations',
  templateUrl: './bookconsultations.component.html',
  styleUrls: ['./bookconsultations.component.scss']
})
export class BookconsultationsComponent implements OnInit {
  flowData: any;
  userData: any;
  noResultReturned: boolean = false;
  pricePerSlot: any;
  selectedSlot: any = null; // To store the selected slot
  selectedDate: Date | null = null; // To store the selected date
  selectedSlotDuration: any = null; // Selected duration 
  selectedTime: string | null = null; // Selected time
  availableTimes: string[] = []; // Available times for the selected duration
  userDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    reason: ''
  };
  currentTab = 0;
  otherUserData: {
    emailAddress: any; rowKey: any; type: string; price: any; // Add the donation amount here
    title: string;
  };
  consultationStartTimeAvailiblity: any;
  consultationEndTimeAvailiblity: any;
  consultationTimeZone: any;
  userConsultationTimeZone: any;

  consultationAvailiblity: string; // Example value
  consultationCustomWeekDays: string[] = []; // Example for weekly
  consultationMonthDay: number | null = null; // Day of the month
  consultationOneDayDate: Date | null = null; // Specific one-day date
  preferredMeetPlatform: string | null = null; // Selected platform

  meetingOptions: { label: string; value: string; }[] = [];
  consultationMeetLink: string; // Example Google Meet link
  consultationZoomLink: string; // Example Zoom link
  preferedMeetPlatform: any;

  dateFilter = (date: Date | null): boolean => {
    if (!date) return false;

    const today = new Date(); // Get today's date
    today.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison

    // Ensure the selected date is not in the past
    if (date < today) {
      return false;
    }

    const day = date.getDay(); // Get day of the week (0 = Sunday, 6 = Saturday)

    switch (this.consultationAvailiblity) {
      case 'Mon-Fri':
        // Allow only Monday to Friday
        return day >= 1 && day <= 5;

      case 'EveryDay':
        // Allow all dates
        return true;

      case 'Weekly':
        // Allow only specific weekdays
        const weekdaysMap: { [key: string]: number } = {
          Sunday: 0,
          Monday: 1,
          Tuesday: 2,
          Wednesday: 3,
          Thursday: 4,
          Friday: 5,
          Saturday: 6,
        };
        return this.consultationCustomWeekDays.some(
          (customDay) => weekdaysMap[customDay] === day
        );

      case 'Monthly':
        // Allow only the specified day of the month
        return this.consultationMonthDay
          ? date.getDate() === this.consultationMonthDay
          : false;

      case 'One Day':
        // Allow only the specific one-day date
        return (
          this.consultationOneDayDate &&
          date.toDateString() ===
          new Date(this.consultationOneDayDate).toDateString()
        );

      default:
        return true;
    }
  };
  enablePaidConsult: any;
  creatorUserConsultation: any;
  showImage: boolean;
  Url: string;
  SelectedTimeSlots: any[] = [];

  ngOnInit(): void {
    this.flowData = JSON.parse(localStorage.getItem("flowData"));
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.creatorUserConsultation = JSON.parse(localStorage.getItem("CreatorUserConsultation"));
    this.userDetails.email = this.userData.emailAddress;
    this.userDetails.phone = this.userData.phoneNumber;
    this.userDetails.firstName = "@" + this.userData.userName;
    console.log(this.flowData);
    console.log(this.userData);
    this.viewUserProfile();
  }
  constructor(private userserviceService: UserserviceService,
    private flowservices: FlowServiceService,
    private router: Router,
    private toast: ToastrService,
    private http: HttpClient
  ) { }

  canSubmit(): boolean {
    return !!(
      this.selectedSlot &&
      this.selectedDate &&
      this.selectedTime &&
      this.userDetails.firstName &&
      // this.userDetails.lastName &&
      this.userDetails.email &&
      // this.userDetails.phone &&
      this.userDetails.reason
    );
  }

  submitDetails() {

    const payload = {
      ContentId: this.flowData != null || undefined ? this.flowData.rowKey : this.creatorUserConsultation.rowKey,
      EmailAddress: this.flowData != null || undefined ? this.flowData.partitionKey : this.creatorUserConsultation.partitionKey,
      ContentTitle: this.flowData != null ? this.flowData.title : this.creatorUserConsultation.userName,
      CustomerUserName: this.userDetails.firstName,
      CreatorUserName: this.flowData != null ? this.flowData.userName : this.creatorUserConsultation.userName,
      ConsultType: this.flowData != null || undefined ? "Flow" : "User",
      ConsultImageUrl: this.Url,
      CustomerEmailAddress: this.userDetails.email,
      CustomerPhoneNumber: this.userDetails.phone,
      SelectedTimeSlot: this.selectedSlot.slotTime,
      SelectedTimeZone: this.consultationTimeZone,
      ReasonForConsultation: this.userDetails.reason,
      PreferedTimeSlot: this.selectedSlot.slotTime,
      AmountPaid: this.selectedSlot.price,
      SelectedDate: this.selectedDate,
      SelectedTime: this.selectedTime,
      PreferedMeetPlatform: this.preferredMeetPlatform,
      IsActive: true,
      IsPaid: false,
      AgreeToTNC: true,
      Status: "Scheduled"
    };

    // API Call
    console.log('Payload:', payload);
    console.log('Paid:', this.enablePaidConsult);
    // Replace with HttpClient to send payload to API
    this.flowservices.bookConsultation(payload)
      .subscribe((data) => {
        // this.pricePerSlot = data.userData.pricePerSlot ;
        console.log(data);
        if (data != null && this.enablePaidConsult) {
          this.otherUserData = {
            emailAddress: this.userData.emailAddress,
            rowKey: data.rowKey,
            type: "Consult",
            price: data.amountPaid, // Add the donation amount here
            title: "Book Consultation with " + data.userName,
            // customerEmail : this.userData.partitionKey
          };
          console.log(this.otherUserData);
          localStorage.setItem("checkoutFlow", JSON.stringify(this.otherUserData));
          this.router.navigate(["/checkout"]);
        } else if (data != null && !this.enablePaidConsult) {
          this.toast.success("Consultation booking is completed");
          this.router.navigate(["/my-consultation"]);
        }
      });
  }


  isStepCoverPageURISet: boolean;
  fileName: string;
  fileData: File = null;


  selectFiles(event) {
    if (event.target.files) {
      this.noResultReturned = true;
      //  
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);
      this.fileName = this.fileData.name;
      this.isStepCoverPageURISet = true;
      if (
        this.fileData.name.includes("JPEG") ||
        this.fileData.name.includes("jpeg") ||
        this.fileData.name.includes("png") ||
        this.fileData.name.includes("jpg") ||
        this.fileData.name.includes("PNG") ||
        this.fileData.name.includes("JFIF") ||
        this.fileData.name.includes("jfif") ||
        this.fileData.name.includes("TIFF") ||
        this.fileData.name.includes("tiff") ||
        this.fileData.name.includes("TIF") ||
        this.fileData.name.includes("tif") ||
        this.fileData.name.includes("JPG")
      ) {
        this.http
          .post(
            environment.apiBaseUrl + environment.uploadImageUrl,
            formData
          )
          .subscribe((data) => {
            var mediaUploadStatus = data as any;
            this.showImage = true;
            //
            this.noResultReturned = false;
            console.log(data);
            if (mediaUploadStatus != null) {
              this.Url =
                environment.imageStorageBaseUrl +
                mediaUploadStatus.fileName;
            } else {
              alert(mediaUploadStatus.message);
            }
          });
      }

    }
  }
  // Triggered when a slot is selected
  onSlotChange(slot: any): void {
    this.selectedSlot = slot;
    this.resetSelections();
  }

  // Triggered when a date is selected
  onDateChange(): void {
    if (this.selectedDate != null) { 
      this.selectedDate = new Date(this.selectedDate.toLocaleDateString('en-CA').split('T')[0]); // Extract YYYY-MM-DD 
     }
     this.availableTimes = [];  // Initialize available times array 
    this.GetAllBookedDated();
    // if (this.selectedSlot) {
    //   console.log(this.SelectedTimeSlots);
    //   this.generateAvailableTimes();
    // }
  }

  // Resets time and available times
  resetSelections(): void {
    this.selectedDate = null;
    this.selectedTime = null;
    this.availableTimes = [];
  }
  timeStringToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  GetAllBookedDated(): void {
    var timeSlotByTime: any[] = [];
    this.noResultReturned = true;

    const flowQueryModel = new FlowQueryModel();
    flowQueryModel.SearchDateTime = this.selectedDate;

    console.log(flowQueryModel);
    this.flowservices
      .getConsultationByDate(flowQueryModel)
      .subscribe((data) => {
        data.forEach(element => { 
          if (element.selectedTime && element.selectedTimeSlot) {
            // Parse the selectedTime (start time)
            const [hours, minutes] = element.selectedTime.split(':').map(Number);
            const startTime = new Date();
            startTime.setHours(hours, minutes, 0, 0); // Set the start time
          
            // Extract the duration from selectedTimeSlot
            const duration = parseInt(element.selectedTimeSlot.split(' ')[0]); // Extract "15" from "15 mins"
          
            // Calculate the end time
            const endTime = new Date(startTime.getTime() + duration * 60 * 1000); // Add duration in milliseconds
          
            // Push the start and end time as a new object in 24-hour format
            timeSlotByTime.push({
              startTime: startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }),
              endTime: endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
            });
          } 
        });
        console.log(timeSlotByTime);
        if (this.selectedSlot) {
          this.generateAvailableTimes(timeSlotByTime);
        }
        console.log(data);
      });
    timeSlotByTime = this.SelectedTimeSlots;
    this.noResultReturned = false;
  }

  generateAvailableTimes(timeSlotByTime): void {
    const interval = this.convertToMinutes(this.selectedSlot.slotTime); // Convert slot time to minutes
    const start = this.timeStringToMinutes(this.consultationStartTimeAvailiblity); // Start time in minutes
    const end = this.timeStringToMinutes(this.consultationEndTimeAvailiblity); // End time in minutes
    let overlaps = false;
    this.availableTimes = [];  // Initialize available times array

    // Generate available time slots
    for (let time = start; time + interval <= end; time += interval) {
      const currentStartTime = time;
      const currentEndTime = time + interval;
      this.availableTimes.push(this.formatTime(currentStartTime));  // Add available time slot
    }

    // Filter out overlapping available times
    // this.availableTimes = this.availableTimes.filter(availableSlot => {

    //   // Check if the current available time overlaps with any time slots in timeSlotByTime
    //   let isOverlapping = false;
    //   for (let index = 0; index < timeSlotByTime.length; index++) {
    //     const slot = timeSlotByTime[index]; // Access the current slot  
    //     slot.startTime = slot.startTime.replace(/am|pm/gi, "").trim();
    //     slot.endTime = slot.endTime.replace(/am|pm/gi, "").trim();
    //     var availableEndTime = availableSlot + interval;
    //     console.log(availableEndTime)
    //     if ((availableSlot >= slot.startTime && availableSlot < slot.endTime) || // Start inside the slot
    //       (availableEndTime > slot.startTime && availableEndTime <= slot.endTime) || // End inside the slot
    //       (availableSlot <= slot.startTime && availableEndTime >= slot.endTime) // Envelops the slot
    //     ) {
    //       isOverlapping = true;
    //     }
    //     // if (availableSlot >= slot.startTime && availableSlot < slot.endTime) {
    //     //   isOverlapping = true; // Mark as overlapping 
    //     // }
    //   }

    //   // Return true if no overlap is found (this keeps the time in availableTimes)
    //   return !isOverlapping;
    // });


    this.availableTimes = this.availableTimes.filter(availableSlot => {
      // Parse the availableSlot into hours and minutes
      const [availableHour, availableMinute] = availableSlot.split(':').map(Number);
    
      // Create a Date object for availableSlot
      const availableStartTime = new Date();
      availableStartTime.setHours(availableHour, availableMinute, 0, 0);
    
      // Add the interval (in minutes) to calculate the end time
      const availableEndTime = new Date(availableStartTime.getTime() + interval * 60 * 1000);
    
      let isOverlapping = false;
    
      for (let index = 0; index < timeSlotByTime.length; index++) {
        const slot = timeSlotByTime[index];
    
        // Parse startTime and endTime of the current slot
        const [slotStartHour, slotStartMinute] = slot.startTime.replace(/am|pm/gi, "").trim().split(':').map(Number);
        const [slotEndHour, slotEndMinute] = slot.endTime.replace(/am|pm/gi, "").trim().split(':').map(Number);
    
        // Create Date objects for slot start and end times
        const slotStartTime = new Date();
        slotStartTime.setHours(slotStartHour, slotStartMinute, 0, 0);
    
        const slotEndTime = new Date();
        slotEndTime.setHours(slotEndHour, slotEndMinute, 0, 0);
    
        // Check for overlap
        if (
          (availableStartTime >= slotStartTime && availableStartTime < slotEndTime) || // Start inside the slot
          (availableEndTime > slotStartTime && availableEndTime <= slotEndTime) || // End inside the slot
          (availableStartTime <= slotStartTime && availableEndTime >= slotEndTime) // Envelops the slot
        ) {
          isOverlapping = true;
          break; // No need to check further if overlapping
        }
      }
    
      // Keep the slot if no overlap
      return !isOverlapping;
    });
    

    // Output the final list of available times
    console.log("Available Times:", this.availableTimes);
  }



  // Helper function to convert duration string like "1 hrs 0 mins" or "10 mins" to minutes
  convertToMinutes(duration: string): number {
    let totalMinutes = 0;
    const regex = /(\d+)\s*(hrs?|mins?)/g;
    let match;

    // Extract hours and minutes from the duration string
    while ((match = regex.exec(duration)) !== null) {
      const value = parseInt(match[1]);
      const unit = match[2].toLowerCase();

      if (unit.startsWith('hr')) {
        totalMinutes += value * 60; // Convert hours to minutes
      } else if (unit.startsWith('min')) {
        totalMinutes += value; // Minutes are already in minutes
      }
    }

    return totalMinutes;
  }

  // Example formatTime function (you may already have this)
  formatTime(time: number): string {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`; // Format time as "HH:MM"
  }

  viewUserProfile(): void {
    //
    this.noResultReturned = true;

    const flowQueryModel = new UserQueryModel();
    flowQueryModel.userEmail = this.userData.emailAddress;
    flowQueryModel.otherUserEmail = this.flowData != null || undefined ?
      this.flowData.partitionKey : this.creatorUserConsultation.partitionKey;

    console.log(flowQueryModel);
    this.userserviceService
      .viewUserProfile(flowQueryModel)
      .subscribe((data) => {
        console.log(data);
        this.pricePerSlot = data.userData.pricePerSlot;
        this.consultationEndTimeAvailiblity = data.userData.consultationEndTimeAvailiblity;
        this.consultationStartTimeAvailiblity = data.userData.consultationStartTimeAvailiblity;
        this.consultationTimeZone = data.userData.consultationTimeZone;
        this.consultationAvailiblity = data.userData.consultationAvailiblity;
        this.consultationCustomWeekDays = data.userData.consultationCustomWeekDays;
        this.consultationMonthDay = data.userData.consultationMonthDay;
        this.consultationOneDayDate = data.userData.consultationOneDayDate;
        this.consultationMeetLink = data.userData.consultationMeetLink;
        this.consultationZoomLink = data.userData.consultationZoomLink;
        this.enablePaidConsult = data.userData.isConsultationPaid;
        console.log(this.consultationEndTimeAvailiblity);
        console.log(this.consultationStartTimeAvailiblity);
        console.log(this.consultationTimeZone);
        console.log(this.pricePerSlot);
        console.log(this.consultationMeetLink);
        console.log(this.consultationZoomLink);
        if (this.consultationMeetLink) {
          this.meetingOptions.push({
            label: 'Google Meet',
            value: this.consultationMeetLink,
          });
        }
        if (this.consultationZoomLink) {
          this.meetingOptions.push({
            label: 'Zoom',
            value: this.consultationZoomLink,
          });
        }
      });
    this.noResultReturned = false;
  }

  nextTab() {
    if (this.currentTab < 3) this.currentTab++;
  }

  prevTab() {
    if (this.currentTab > 0) this.currentTab--;
  }
  toggleTimeZone(day) {
    console.log(day);
    this.consultationTimeZone = day;
    console.log(this.consultationTimeZone);
  }
  timezones = [
    { value: "UTC-12:00", label: "UTC-12:00" },
    { value: "UTC-11:00", label: "UTC-11:00" },
    { value: "UTC-10:00", label: "UTC-10:00" },
    { value: "UTC-09:00", label: "UTC-09:00" },
    { value: "UTC-08:00", label: "UTC-08:00 (PST)" },
    { value: "UTC-07:00", label: "UTC-07:00 (MST)" },
    { value: "UTC-06:00", label: "UTC-06:00 (CST)" },
    { value: "UTC-05:00", label: "UTC-05:00 (EST)" },
    { value: "UTC-04:00", label: "UTC-04:00 (AST)" },
    { value: "UTC-03:00", label: "UTC-03:00" },
    { value: "UTC-02:00", label: "UTC-02:00" },
    { value: "UTC-01:00", label: "UTC-01:00" },
    { value: "UTC+00:00", label: "UTC+00:00 (GMT)" },
    { value: "UTC+01:00", label: "UTC+01:00 (CET)" },
    { value: "UTC+02:00", label: "UTC+02:00 (EET)" },
    { value: "UTC+03:00", label: "UTC+03:00 (MSK)" },
    { value: "UTC+04:00", label: "UTC+04:00" },
    { value: "UTC+05:00", label: "UTC+05:00" },
    { value: "UTC+05:30", label: "UTC+05:30 (IST)" },
    { value: "UTC+06:00", label: "UTC+06:00" },
    { value: "UTC+07:00", label: "UTC+07:00" },
    { value: "UTC+08:00", label: "UTC+08:00 (CST)" },
    { value: "UTC+09:00", label: "UTC+09:00 (JST)" },
    { value: "UTC+09:30", label: "UTC+09:30 (ACST)" },
    { value: "UTC+10:00", label: "UTC+10:00 (AEST)" },
    { value: "UTC+11:00", label: "UTC+11:00" },
    { value: "UTC+12:00", label: "UTC+12:00 (NZST)" },
    { value: "UTC+13:00", label: "UTC+13:00" },
    { value: "UTC+14:00", label: "UTC+14:00" },
  ];
}
