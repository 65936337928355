import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-checkout-navigator',
  templateUrl: './checkout-navigator.component.html',
  styleUrls: ['./checkout-navigator.component.scss']
})
export class CheckoutNavigatorComponent implements OnInit {
  tempFlows: any;
  flowData: any;
  flowCoverImageUrl: any;
  userData: any;
  selectedBundleData: any;
  bundleList: any;
  ngOnInit(): void {
    this.tempFlows = JSON.parse(localStorage.getItem("BundleListToShare")) || [];
    this.flowData = JSON.parse(localStorage.getItem("flowData")) || [];
    this.flowCoverImageUrl = "url(" + '"' + this.flowData.url + '"' + ")";
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    // console.log(this.bundleList);    
    // this.bundleList.forEach((bundle : any)=>{
    //   if(bundle.isPaid){
    //     this.tempFlows.push(bundle);
    //   }
    // });
    // console.log(this.tempFlows);
    // console.log(this.flowData);
  }
  constructor(private router: Router,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<CheckoutNavigatorComponent>,
  ) { }

  confirm(choice: string) {
    this.purchaseFlow(choice);
    this.close();

  }
  onBundleSelect(bundle: any): void {
    this.selectedBundleData = bundle;
    console.log(this.selectedBundleData);
  }
  purchaseFlow(choice) {
    if (choice === 'flow') {
      if (
        this.flowData.price != null &&
        this.flowData.isPaid &&
        this.userData == null
      ) {
        localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
        localStorage.setItem("returnUrl", this.router.url);
        this.router.navigate(['/registration']);
      } else if (
        this.flowData.price != null &&
        this.flowData.isPaid &&
        !this.flowData.isFlowPurchased &&
        this.userData.emailAddress != this.flowData.customerEmail
      ) {
        if (this.userData != null) {
          localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
          // this.router.navigate(["/checkout"]);
          // this.dialogRef.close();
          this.router.navigate(["/checkout"]).then(() => {
            this.dialogRef.close(); // Close the dialog only after navigation succeeds
          });
        } else {
          localStorage.setItem("returnUrl", this.router.url);
          this.router.navigate(['/registration']);
        }
      }
    } else if (choice === 'bundle' && this.selectedBundleData) {
      console.log(this.selectedBundleData);
      var email = "";
      if (this.userData != null && this.userData != undefined) {
        email = this.userData.emailAddress;
      }
      if (
        this.selectedBundleData.price != null &&
        this.selectedBundleData.isPaid &&
        this.userData == null
      ) {
        localStorage.setItem("checkoutFlow", JSON.stringify(this.selectedBundleData));
        localStorage.setItem("returnUrl", this.router.url);
        this.router.navigate(['/registration']);
      } else if (
        this.selectedBundleData.price != null &&
        this.selectedBundleData.isPaid &&
        !this.selectedBundleData.isFlowPurchased &&
        email != this.selectedBundleData.customerEmail
      ) {
        if (this.userData != null) {
          localStorage.setItem("checkoutFlow", JSON.stringify(this.selectedBundleData));
          // this.router.navigate(["/checkout"]);
          // this.dialogRef.close();
          this.router.navigate(["/checkout"]).then(() => {
            this.dialogRef.close(); // Close the dialog only after navigation succeeds
          });
        } else {
          localStorage.setItem("returnUrl", this.router.url);
          this.router.navigate(['/registration']);
        }
      }
    } else {
      this.toast.warning('Please select a bundle to proceed.');
    }
  }

  close() {
    this.dialogRef.close();
    const overlayContainer = document.querySelector('.cdk-overlay-container');
    if (overlayContainer) {
      overlayContainer.innerHTML = '';
    }
  }


  flowClicked(flow) {
    localStorage.setItem("notificationflowId", null);
    //localStorage.setItem("flow", JSON.stringify(flow));
    localStorage.removeItem("flow"); //, JSON.stringify(flow));
    localStorage.setItem("navigateTo", "grid");

    if (flow.type == "Bundle") {
      //this.isItBundle = true;
      this.router.navigate(["/viewbundle/" + flow.rowKey]);
    } else {
      //this.isItBundle = false;
      this.router.navigate(["/view/" + flow.rowKey]);
    }
    this.close();
  }
}
