import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { DataService } from "../service/data.service";
import { SessionService } from "../service/session.service";
import { ApiService } from "../service/apiservice.service";
import { HttpHeaders, HttpClient } from "@angular/common/http";

import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  FormControlName,
  ValidatorFn,
  AbstractControl,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { UserserviceService } from "src/app/Services/userservice.service";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/Services/auth.service";
import { ErrorStateMatcher } from "@angular/material/core";
import { TokenService } from "src/app/Services/token.service";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  showNotificationCount: boolean;
  notificationCount: number;
  fieldTextType: boolean;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  noResultReturned: boolean;
  matcher = new MyErrorStateMatcher(); 
  showPassword: boolean = false; 
  returnUrl: string = '/';


  constructor(
    private data: DataService,
    //public translate: TranslateService,
    private session: SessionService,
    public route: Router,
    private apiService: ApiService,
    private http: HttpClient,
    private activateroute: ActivatedRoute,
    private router: Router,
    public formbuilder: UntypedFormBuilder,
    private userserviceService: UserserviceService,
    private toastr: ToastrService,
    private authService: AuthService,
    private tokenService: TokenService
  ) {
    this.userData = localStorage.removeItem("LoggedInUser");
  }

  loginForm = new UntypedFormGroup({
    EmailAddress: new UntypedFormControl("", [Validators.required, Validators.email]),
    Password: new UntypedFormControl("", Validators.required),
  });

  PersonModel: any = [];
  EmailAddress: string;
  Password: string;

  @ViewChild("email", { static: true }) email: ElementRef;
  @ViewChild("password", { static: true }) password: ElementRef;
  alertCtrl: any;
  dataService: any;

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() { 
    this.returnUrl = this.activateroute.snapshot.queryParamMap.get('returnUrl') || '/';
    this.userData = localStorage.removeItem("LoggedInUser");
    this.loginForm = this.formbuilder.group({
      Password: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(8),
      ]),

      EmailAddress: new UntypedFormControl("", [
        Validators.required,
        Validators.email,
      ]),
    });

    this.getProfileUser();
    this.returnUrl= localStorage.getItem("returnUrl");
    console.log(this.returnUrl);
  }
  // togglePasswordVisibility(): void {
  //   this.showPassword = !this.showPassword;
  // }

  // tslint:disable-next-line:variable-name
  equalto(field_name: string | number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;

      const isValid = control.root.value[field_name] === input;
      if (!isValid) {
        return { equalTo: { isValid } };
      } else {
        return null;
      }
    };
  }

  resolved(captchaResponse: string) {

  }

  toggle() {
    this.fieldTextType = !this.fieldTextType;
  }

  showToast() {
    this.toastr.success("Login Successful");
  }

  showToastForLogout() {
    this.toastr.success("Logout Successful", "title");
  }

  signIn() {
    const pushNotificationToken = localStorage.getItem("pushNotificationToken");

    const loginModel = {
      userName: this.EmailAddress,
      password: this.Password,
      WebPushNotificationToken: pushNotificationToken,
    }; 
    this.userserviceService
      .login(loginModel)
      .subscribe(
        (data) => {
          console.log(data);
          if (data != null && data.userModel != null) { 
            
            if (data.userModel.isActive) {
              localStorage.setItem(
                "LoggedInUser",
                JSON.stringify(data.userModel)
              );
              localStorage.setItem(
                "userObjWithTokens",
                JSON.stringify(data)
              );
              this.showToast();

              this.tokenService.saveToken(data.accessToken);
              this.tokenService.saveRefreshToken(data.refreshToken);

              var flowData = JSON.parse(
                localStorage.getItem("checkoutFlow")
              );

              if (flowData != null) {
                flowData.Type == "Bundle"
                  ? this.router.navigate(["/viewbundle/" + flowData.rowKey])
                  : this.router.navigate(["/view/" + flowData.rowKey]);
              } else {
                this.router.navigateByUrl(this.returnUrl);
                // this.router.navigate(["/grid"]);
              }
              this.router.navigateByUrl(this.returnUrl);
            } else {
              this.toastr.error(
                "Account is not activated. Please activate account using the code sent to your email account"
              );
              localStorage.setItem(
                "activateaccountemailaddress",
                this.EmailAddress
              );
              this.router.navigate(['/activateaccount'], {
                queryParams: { returnUrl: this.router.url }
              }); 
            //  this.router.navigate(["/activateaccount"]);
            }
          } else {
            this.noResultReturned = false;
            this.toastr.error("Login Failed");
          }
        },
        (error) => {
          this.toastr.error("Invalid Email or Password");
          // 
          this.noResultReturned = false;
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: this.router.url }
          }); 
        }
      );
    //   },
    //   (err: any) => {

    //   }
    // );
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  getProfileUser(): void {
    if (this.userData != null) {
      
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
          localStorage.setItem(
            "LoggedInUser",
            JSON.stringify(data.userModel)
          );
        });
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.showToastForLogout();
    this.authService.logout();
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
  }
}
