import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FlowQueryModel } from 'src/app/models/flowquerymodel';
import { FlowServiceService } from 'src/app/Services/flow-service.service';
import { MeetLinkDialogComponent } from '../meet-link-dialog/meet-link-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scheduleconsultations',
  templateUrl: './scheduleconsultations.component.html',
  styleUrls: ['./scheduleconsultations.component.scss']
})
export class ScheduleconsultationsComponent implements OnInit {
  pastConsulations: any;
  noResultReturned: boolean;
  userData: any;
  previousDate: any;
  filteredConsultations: any[] = [];
  selectedFilter: string = '';

  constructor(private flowServiceService: FlowServiceService,
    private toast: ToastrService,
    public dialog: MatDialog,
    public router: Router,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.getFundDetails();  
  }

  applyFilter() {
    const today = new Date();
    console.log(this.pastConsulations);
    this.filteredConsultations = this.pastConsulations.filter((data) => {
      // Check if selectedDate is valid
      if (!data.createdDateTime || data.createdDateTime == undefined) {
        return false; // Exclude entries with undefined or null selectedDate
      }
      const selectedDate = new Date(data.createdDateTime);
      switch (this.selectedFilter) {
        case 'today':
          return (
            selectedDate.toDateString() === today.toDateString()
          );
        case 'yesterday':
          const yesterday = new Date();
          yesterday.setDate(today.getDate() - 1);
          return (
            selectedDate.toDateString() === yesterday.toDateString()
          );
        case 'last7days':
          const lastWeek = new Date();
          lastWeek.setDate(today.getDate() - 7);
          return selectedDate >= lastWeek && selectedDate <= today;
        case 'thisMonth':
          return (
            selectedDate.getMonth() === today.getMonth() &&
            selectedDate.getFullYear() === today.getFullYear()
          );
        default:
          return true; // Show all for no filter
      }
    });
    console.log(this.filteredConsultations);
  }
  getFundDetails(): void {
    this.noResultReturned = true;
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.partitionKey
    });

    this.flowServiceService.getUserConsultation(flowQueryModel).subscribe((data) => {
      // If data is available, filter and sort it
      if (data && data.length > 0) {
        // Sort the data in descending order (latest to old)
        this.pastConsulations = data.sort((a, b) => {
          const dateA = new Date(a.createdDateTime); // Adjust this field to the correct date field
          const dateB = new Date(b.createdDateTime); // Adjust this field to the correct date field
          return dateB.getTime() - dateA.getTime(); // Sorting in descending order
        });
        this.filteredConsultations = this.pastConsulations; // Initialize filteredConsultations
        console.log(this.pastConsulations);
      } else {
        this.pastConsulations = [];
        this.noResultReturned = false; // No data found, so set noResultReturned to false
      }
    });
  }

  onStatusChange(data: any) {
    if (data.status === 'Scheduled' || data.status === 'Rescheduled') {
      if (!data.isPaid && !data.isActive) {
        data.status = 'Pending'; // Revert back to Pending
        this.toast.warning(
          'Cannot change status to Scheduled/Rescheduled as user is yet to make payment'
        );
      } else {
        this.confirmChanges(data);
        this.toast.success(
          `Consultation link ${data.status === 'Scheduled' ? 'generated' : 'updated'} successfully`
        );
        // const dialogRef = this.dialog.open(MeetLinkDialogComponent, {
        //   width: '400px',
        //   data: { link: data.consultationLink } // Pass existing link if rescheduling
        // });

        // dialogRef.afterClosed().subscribe((result) => {
        //   if (result) {
        //     data.consultationLink = result; // Update the consultation link
        //     console.log(result);
        //     this.toast.success(
        //       `Consultation link ${data.status === 'Scheduled' ? 'generated' : 'updated'} successfully`
        //     );
        //     // Save the updated data back to the array
        //     const index = this.pastConsulations.findIndex((item) => item.rowKey === data.rowKey);
        //     if (index !== -1) {
        //       this.pastConsulations[index] = { ...data };
        //     }
        //     this.cdr.detectChanges();
        //     console.log(this.pastConsulations);
        //   } else {
        //     data.status = 'Pending'; // Revert back to Pending if canceled
        //     this.toast.warning('Status change canceled as no link was provided');
        //     this.cdr.detectChanges();
        //   }
        // });
        // this.cdr.detectChanges();
      }
    } else if (data.status === 'Canceled') {
      this.confirmChanges(data);
      this.toast.info('Consultation has been canceled');
      data.preferedMeetPlatform = null; // Clear consultation link if canceled
    }
    const index = this.pastConsulations.findIndex((item) => item.rowKey === data.rowKey);
    if (index !== -1) {
      this.pastConsulations[index] = { ...data };
    }
    console.log(this.pastConsulations);
  }


  enableDateEdit(data: any) {
    data.isEditingDate = true;
    this.previousDate = data.selectedDate;
  }

  disableDateEdit(data: any) {
    data.isEditingDate = false;
  }

  onDateChange(data: any) {
    const selectedDate = new Date(data.selectedDate);
    const minDate = new Date(this.getMinDate());
    const maxDate = new Date(this.getMaxDate());
    if (selectedDate < minDate || selectedDate > maxDate) {
      alert('Selected date must be within the allowed range.');
      // data.selectedDate = ''; // Reset to a default or previous value if invalid
    }
    this.confirmChanges(data);
  }

  getMinDate(): string {
    const date = new Date(); // Current date
    return date.toISOString().split('T')[0];
  }

  getMaxDate(): string {
    const date = new Date();
    date.setMonth(date.getMonth() + 1); // One month from today
    return date.toISOString().split('T')[0];
  }

  confirmChanges(data: any) {
    if (data.status === 'Scheduled' && (!data.isPaid || !data.isActive)) {
      this.toast.warning(
        'Cannot change status to Scheduled/Rescheduled as user is yet to make payment'
      );
      return;
    }
    // Make API call to save changes
    this.flowServiceService.bookConsultation(data).subscribe((response) => {
      console.log("response");
      this.toast.success(
        'Succesfully updated status for consultation'
      );
    });
  }

  currentDateTime: Date = new Date(); // Current date and time

  isFeedbackAllowed(data: any): boolean {
    const { selectedDate, selectedTime, selectedTimeSlot } = data;

    // Parse `selectedDate` and `selectedTime`
    const meetingDateTime = new Date(selectedDate);
    const [hours, minutes] = selectedTime.split(':').map(Number);
    meetingDateTime.setHours(hours, minutes);

    // Add `selectedTimeSlot` to calculate end time
    const timeSlotParts = selectedTimeSlot.match(/(\d+)\s*hrs\s*(\d+)?\s*mins?/);
    if (timeSlotParts) {
      const additionalHours = parseInt(timeSlotParts[1], 10) || 0;
      const additionalMinutes = parseInt(timeSlotParts[2], 10) || 0;
      meetingDateTime.setHours(meetingDateTime.getHours() + additionalHours);
      meetingDateTime.setMinutes(meetingDateTime.getMinutes() + additionalMinutes);
    }

    // Compare the meeting end time with the current time
    return this.currentDateTime > meetingDateTime;
  }

  joinMeet(link: string) {
    if (link) {
      window.open(link, '_blank');
    } else {
      console.error('Invalid link provided');
    }
  }
  submitFeedback(data) {
    if (this.userData.partitionKey === data.customerEmailAddress && data.isCreatorFeedBackSubmitted) {
      this.toast.success("Already submitted the feedback");
    } else if (this.userData.partitionKey != data.customerEmailAddress && data.isUserFeedBackSubmitted) {
      this.toast.success("Already submitted the feedback");
    } else {
      localStorage.setItem("feedbackContentCreatorData", JSON.stringify(data));
      this.router.navigateByUrl("/feedback");
    }
  }
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hour = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    return `${year}${month}${day}T${hour}${minutes}00Z`; // Google Calendar format
  }

  addToCalendar(data: any): void {
    const startDate = new Date(data.selectedDate); // Assuming selectedDate is in a valid date format
    const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // 1 hour meeting duration (customize as needed)

    const calendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=Consultation+with+${data.customerFirstName}+${data.customerLastName}&dates=${this.formatDate(startDate)}/${this.formatDate(endDate)}&details=${data.reasonForConsultation}&location=${data.preferedMeetPlatform}&sf=true`;

    window.open(calendarUrl, '_blank');
  }
}
