import { FlowServiceService } from "src/app/Services/flow-service.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Endpoint } from "../shared/endpoints";
import { environment } from "src/environments/environment";
import { FlowQueryModel, UserQueryModel } from "../models/flowquerymodel";

@Injectable({
  providedIn: "root",
})
export class UserserviceService {
  httpClient: any;


  constructor(
    private http: HttpClient,
    public flowServiceService: FlowServiceService
  ) { }

  getUserByUserName(uname) {
    let header = new HttpHeaders();
    header = header.set("userName", uname);
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getUserByUserNameUrl, {
      headers: header,
    });
  }

  getActivationCode(userEmail) {
    let header = new HttpHeaders();
    header = header.set("userEmail", userEmail);
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getActivationCodeUrl, {
      headers: header,
    });
  }

  saveUser(apiurl, userModel) {
    const urlToCall = environment.apiBaseUrl + apiurl;
    const headers = new HttpHeaders();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    headers.append("emailAddress", userModel.EmailAddress);
    return this.http.post<any>(environment.apiBaseUrl + apiurl, userModel, {
      headers,
    });
  }

  searchInUsers(flowQueryModel: FlowQueryModel) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.searchUsersUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  searchInFaqs(flowQueryModel: FlowQueryModel) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.searchFaqsUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }
  
  ActivateAccount(apiurl, userModel) { 
    const header = new HttpHeaders(); 
    return this.http.post<any>(environment.apiBaseUrl + apiurl, userModel, {
      headers:header,
    });
  }

  UpdateCustomerProfileDetails(apiurl, userModel) {
    const headers = new HttpHeaders();  
    return this.http.post<any>(environment.apiBaseUrl + apiurl, userModel, {
      headers:headers,
    });
  }
  UpdateSettingsForCustomerProfileDetails( userModel) {
    const headers = new HttpHeaders();  
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.saveSettingsForCustomer, userModel, {
      headers:headers,
    });
  }
  UpdateNotifySettingsForCustomerProfileDetails( userModel) {
    const headers = new HttpHeaders();  
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.saveNotifySettingsForCustomer, userModel, {
      headers:headers,
    });
  }
  UpdateDonateSettingsForCustomerProfileDetails( userModel) {
    const headers = new HttpHeaders();  
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.saveDonateSettingsForCustomer, userModel, {
      headers:headers,
    });
  }

  changeOldPassword(apiurl, userModel) { 
    const headers = new HttpHeaders(); 
    return this.http.post<any>(environment.apiBaseUrl + apiurl, userModel, {
      headers,
    });
  }

  getAllUsers(flowQueryModel: FlowQueryModel): Observable<any> { 
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getAllUsersUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  viewUserProfile(userQeryModel: UserQueryModel): Observable<any> { 
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.viewUserProfileUrl,
      userQeryModel,
      {
        headers: header,
      }
    );
  }

  getUserWithEmailAddressExists(loginModel): Observable<any> {
    const header = new HttpHeaders();

    return this.http.get<any>(environment.apiBaseUrl + Endpoint.userEmailExists, {
        headers: header,
      });  
  }

  checkUserWithEmailAddressExists(loginModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.userEmailExists, loginModel, {
      headers: header,
    });
  } 

  getProfilepic(): Observable<any> {
    let header = new HttpHeaders();
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getProfileUrl, {
      headers: header,
    });
  }

  getPreviewUser(): Observable<any> {
    let header = new HttpHeaders();
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getProfileUrl, {
      headers: header,
    });
  }

  getProfileUser(): Observable<any> {
    let header = new HttpHeaders();
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getProfileUrl, {
      headers: header,
    });
  }

  getEditProfile(): Observable<any> {
    let header = new HttpHeaders();
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getProfileUrl, {
      headers: header,
    });
  }

  userNameChanged(loginModel): Observable<any> {
    const header = new HttpHeaders();

    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.changeUserNameUrl,
      loginModel,
      {
        headers: header,
      }
    );
  }
  deleteCustomer(commentModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.deleteUser, commentModel, {
      headers: header,
    });
  }
  getUpdateProfile(baseurl, user) {
    const customerModel = {
      EmailAddress: user.email,
      PhoneNumber: user.phone,
      Info: user.info,
      Website: user.website,
      UserName: user.uname,
      FirstName: user.name,
      ProfilePicUrl: user.profilePicture,
      ShowAboutYou: user.showAboutYou,
      ShowEmail: user.showEmail,
      ShowName: user.showName,
      ShowWebsite: user.showWebsite,
      ShowPhone: user.showPhone,
    };
    return this.httpClient.post(baseurl, customerModel);
  }

  login(userModel) {
    const urlToCall = environment.apiBaseUrl + Endpoint.login;
    const headers = new HttpHeaders();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    return this.http.post<any>(urlToCall, userModel, {
      headers,
    });
  }

  register(baseurl, user) {
    const usermodel = {
      FullName: user.FullName,

      EmailAddress: user.EmailAddress,
      MobileNumber: user.MobileNumber,
      Password: user.Password,
      ConfirmPassword: user.ConfirmPassword,
      Address: {
        AddressText: user.Address[0].AddressText,
      },

      LoginModel: {
        UserName: user.EmailAddress,
        Password: user.Password,
        ConfirmPassword: user.ConfirmPassword,
      },
    };
    return this.httpClient.post(baseurl, usermodel);
  }
}
