import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/Services/dataservice.service";

import { FlowServiceService } from "src/app/Services/flow-service.service";
import { Router } from "@angular/router";
import { UserserviceService } from "src/app/Services/userservice.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CommentComponent } from "../comment/comment.component";

@Component({
  selector: "app-edit-comment",
  templateUrl: "./edit-comment.component.html",
  styleUrls: ["./edit-comment.component.scss"],
})
export class EditCommentComponent implements OnInit {
  comment: any;
  comments = [];
  flowData: any;
  userData: any;
  profilePicture: string;
  userDisplayName: any;

  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  showNotificationCount: boolean;
  notificationCount: number;

  constructor(
    private dataService: DataService,
    
    private flowServiceService: FlowServiceService,
    private router: Router,
    private userserviceService: UserserviceService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.dataService.currentMessage.subscribe((comment) => {
      this.comment = comment;
    });
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser")); 
    this.getProfileUser();
  }

  save() {
    this.flowServiceService.editComment(this.comment).subscribe((data) => {
      if (this.userData != null) {
        this.flowData = JSON.parse(localStorage.getItem("flowcommentData"));
        if (this.flowData == null) {
          this.flowData = JSON.parse(localStorage.getItem("bundlecommentData"));
          this.router.navigate(["/viewbundle/" + this.flowData.rowKey]);
        }
        this.router.navigate(["/view/" + this.flowData.rowKey]);
        const dialogRef = this.dialog.open(CommentComponent, {
          panelClass: "dialoguebox",
        });
        dialogRef.afterClosed().subscribe((result) => { 
        });
      } else {
        this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
      }
    });
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  getProfileUser(): void {
    if (this.userData != null) {
      
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
  }
}
