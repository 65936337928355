import { BundledialogComponent } from "./pages/bundledialog/bundledialog.component";
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  VERSION,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Route, Router, NavigationEnd } from "@angular/router";
import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "./Services/flow-service.service";
import { UserserviceService } from "./Services/userservice.service";

import { EventEmitterService } from "./Services/event-emitter.service";
import { UntypedFormControl } from "@angular/forms";
import { map, startWith, filter } from "rxjs/operators";
import { Observable, partition } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Location } from "@angular/common";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "./Services/auth.service";
import { FlowQueryModel } from "./models/flowquerymodel";
import { DeleteUserDialogComponent } from "./pages/delete-user-dialog/delete-user-dialog.component";

declare var firebase: any;
declare var gtag: any;
// declare ga as a function to set and sent the events
declare let ga: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public repoUrl: string;
  today: Date = new Date();
  yesterday: Date = new Date();
  thisWeekStart: Date = new Date(); 
  notificationsToday: any[] = [];
  notificationsYesterday: any[] = [];
  notificationsThisWeek: any[] = [];
  notificationsOlder: any[] = [];
  myControl = new UntypedFormControl();
  options: string[] = [
    "Flow 1",
    "Flow 2",
    "Flow 3",
    "Flow 4",
    "Flow 5",
    "Flow 6",
    "Flow 7",
    "Flow 8",
    "Flow 9",
    "Flow 10",
  ];
  filteredOptions: Observable<string[]>;

  qrcodename: string;
  title = "generate-qrcode";
  // elementType: 'url' | 'canvas' | 'img' = 'url';
  value: string;
  display = false;
  href: string;

  // ename = 'Angular' +VERSION.major;

  // correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  // value='https://flowchest.azurewebsites.net/grid';

  isLogin = false;
  message;
  notificationtitle: any;
  notificationbody: any;
  notificationsarray: any[] = [];
  notificationscount: number;
  showNotificationCount: boolean;
  flows: any;
  currentImage: any;
  previousIndex: any;
  showImg = false;
  arrow = false;
  itemCount = 0;
  scrHeight: any;
  scrWidth: any;
  SlideOptions: any;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  isAdmin: any;
  categories: any[] = [];

  homebgColor: any;
  homeColor: any;

  searchbgColor: any;
  searchColor: any;

  emoji_objectsbgColor: any;
  emoji_objectsColor: any;

  add_circle_outlinebgColor: any;
  add_circle_outlineColor: any;

  notificationsbgColor: any;
  notificationsColor: any;

  profilebgColor: any;
  profileColor: any;
  turnOnNightMode: string;
  menubgColor: any;
  menuColor: any;

  langList: any[] = [
    { value: "en", display: "English" },
    { value: "tr", display: "Turkish" },
  ];
  account_circleColor: string;
  account_circlebgColor: string;
  personColor: string;
  personbgColor: string;
  getNotificationUrl: string;
  userDisplayEmail: any;
  ProfilePicUrl: any;
  noResultReturned: boolean;
  tempNotifications: any = [];
  infiniteScrollCount: number;
  isMobileView: boolean;
  add_circle_outlineSize: any;
  isthereanyNotifications: boolean;
  isuserdashboard: boolean;
  profile_outlineSize: string;
  account_border: string;
  account_borderRadius: string;
  notifications: boolean;
  constructor(
    private activateRouter: ActivatedRoute,
    private flowServiceService: FlowServiceService,
    private dataService: DataService,
    private router: Router,
    private userserviceService: UserserviceService,
    //
    private data: DataService,
    public route: Router,
    private toastr: ToastrService,
    // private translateService: TranslateService,
    private eventEmitterService: EventEmitterService,
    private location: Location,
    public dialog: MatDialog,
    private authService: AuthService
  ) {
    this.turnOnNightMode = "Turn On Night Mode";
    this.infiniteScrollCount = 5;
    this.getNotificationUrl = "v1/Notification/GetUserNotifications";
    // this.translateService.addLangs(["en", "tr"]);
    // this.translateService.setDefaultLang("en");
    // this.translateService.use("en");

    if (this.eventEmitterService.loginCompleted === undefined) {
      this.eventEmitterService.loginCompleted =
        this.eventEmitterService.invokeComponentFunction.subscribe(
          (name: string) => {
            this.getProfileUser();
          }
        );
    }

    if (
      this.eventEmitterService.setHomePageIconColorFunctionCompleted ===
      undefined
    ) {
      this.eventEmitterService.setHomePageIconColorFunctionCompleted =
        this.eventEmitterService.setHomePageIconColorFunction.subscribe(
          (name: string) => {
            this.setPageColor(name);
          }
        );
    }

    if (this.eventEmitterService.setUserProfileCreateIconClickedEventCompleted === undefined) {
      this.eventEmitterService.setUserProfileCreateIconClickedEventCompleted =
        this.eventEmitterService.setUserProfileCreateIconClickedFunction.subscribe(
          (name: string) => {
            this.iconClicked(name);
          }
        );
    }

    const navEndEvents = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag("config", "G-3MY3PGF39Q", {
        "page-path": event.urlAfterRedirects,
      });
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag("config", "GTM-T7JVMSK", {
          page_path: event.urlAfterRedirects,
        });
      }
    });

    // subscribe to router events and send page views to Google Analytics
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        ga("set", "page", event.urlAfterRedirects);
        ga("send", "pageview");
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) { }
  onResize(event) {
    const width = window.innerWidth || document.body.clientWidth;

    if (width <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  ChangeLanguage(value) {
    // this.translateService.setDefaultLang(value);
    // this.translateService.use(value);
  }

  showToast() {
    this.toastr.success("Successfully Logged In", "title");
  }

  showToastForLogout() {
    this.toastr.success("Logout Successful");
  }

  Logout() {
    this.data.changeMessagebool(false);
    localStorage.setItem("isLogin", "No");
    this.showToastForLogout();
    this.route.navigateByUrl("/login");
  }  
  
  fnturnOnNightMode() {
    if (this.turnOnNightMode == "Turn On Night Mode") {
      document.body.className = "darkmode";
      this.turnOnNightMode = "Turn Off Night Mode";
    } else {
      this.turnOnNightMode = "Turn On Night Mode";
      document.body.className = null;
    }
  }

  ngOnInit() {
    
    this.yesterday.setDate(this.yesterday.getDate() - 1);
    this.thisWeekStart.setDate(this.thisWeekStart.getDate() - this.thisWeekStart.getDay());

    this.repoUrl = "http://www.Flowchest.com"; //urle shared
    this.homeColor = "#5ea1d8 !important";
    if (this.userData != null) {
      //this.eventEmitterService.setUserProfileCreateIconClickedEvent("search");
      this.userDisplayName = this.userData.firstName;
      this.userDisplayEmail = this.userData.emailAddress;
      this.email = this.userData.emailAddress;
      this.phone = this.userData.phoneNumber;
      this.info = this.userData.info;
      this.website = this.userData.website;
      this.uname = this.userData.userName;
      this.name = this.userData.firstName;
      this.isAdmin = this.userData.isAdmin;
      //this.getProfileUser();
    }
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
      this.getnotification();
    }

    if (this.route.url !== "/" || localStorage.getItem("isLogin") === "Yes") {
      this.isLogin = true;
    } else {
      this.data.currentMessagebool.subscribe((res) => (this.isLogin = res));
    }

    this.initialize();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  homePage() {
    this.router.navigate(["/home"]);
    this.homeColor = "#5ea1d8";
    this.searchColor = "#5ea1d8 !important";
    this.emoji_objectsColor = "#212427";
    this.notificationsColor = "#212427";
    this.add_circle_outlineColor = "#212427";
    this.account_circleColor = "#212427";
    this.profileColor = "#212427";
    this.personColor = "#212427";
  }

  setPageColor(page) {

    if (page == "home") {
      this.homeColor = "#5ea1d8";
      this.searchColor = "#5ea1d8";
      this.emoji_objectsColor = "#212427";
      this.notificationsColor = "#212427";
      this.add_circle_outlineColor = "#212427";
      this.account_circleColor = "#212427";
      this.profileColor = "#212427";
      this.personColor = "#212427";
    }

    if (page == "search") {
      this.homeColor = "#212427";
      this.searchColor = "#5ea1d8 !important";
      this.emoji_objectsColor = "#212427";
      this.notificationsColor = "#212427";
      this.add_circle_outlineColor = "#212427";
      this.account_circleColor = "#212427";
      this.profileColor = "#212427";
      this.personColor = "#212427";
    }

    if (page == "bulb") {
      this.homeColor = "#212427";
      this.searchColor = "#212427";
      this.emoji_objectsColor = "#5ea1d8";
      this.notificationsColor = "#212427";
      this.add_circle_outlineColor = "#212427";
      this.account_circleColor = "#212427";
      this.profileColor = "#212427";
      this.personColor = "#212427";
    }

    if (page == "user") {
      this.homeColor = "#212427";
      this.searchColor = "#212427";
      this.emoji_objectsColor = "#212427";
      this.notificationsColor = "#212427";
      this.add_circle_outlineColor = "#212427";
      this.account_circleColor = "#5ea1d8";
      this.profileColor = "#212427";
      this.personColor = "#212427";
    }
  }

  initialize() {
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey(
      "BFb9qcr5auxO24GEIH-donPWnx-cRtdIqzV5vAE1ITycrzAWMkjRS_kVAQiIFcUKW3BymKmllpbMVMblYZihzC0"
    );
    messaging.onMessage((payload) => {
      this.notificationtitle = payload.data.notificationMessage;
      this.notificationsarray.unshift({
        notification: payload.data,
      });

      this.notificationsarray.forEach((element) => { });

      if (this.notificationsarray.length > 0) {
        this.showNotificationCount = true;
      }

      this.notificationscount = this.notificationsarray.length;
      this.eventEmitterService.setNotificationDetailsEvent(
        JSON.stringify(this.notificationsarray)
      );
      if (this.notificationsarray.length < this.infiniteScrollCount) {
        this.notificationsarray.forEach((element) => {
          this.tempNotifications.push(element);
        });
      } else {
        for (var i = 0; i < this.infiniteScrollCount; i++) {
          this.tempNotifications.push(this.notificationsarray[i]);
        }
      }
    });
    this.notificationscount = 0;
    messaging.getToken().then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("pushNotificationToken", currentToken);
      } else {
        // Show permission request.
      }
    });
  }
  resetNotification() {
    this.notificationscount = 0;
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    } else {
      localStorage.removeItem("ItemsRequired");
      localStorage.removeItem("flowCoverPageDetails");
      localStorage.removeItem("SupportingDocumentDocuments");
      localStorage.removeItem("SupportingDocumentHyperLinksRequired");
      localStorage.removeItem("editBundlesupportingDocumentHyperLinksIndex");
      localStorage.removeItem("BundleSupportingDocumentHyperLinksRequired");
      localStorage.removeItem("editBundleSupportingDocumentRequiredIndex");
      localStorage.removeItem("BundleSupportingDocumentDocuments");
      localStorage.removeItem("stepsCoverPageDetails");
      localStorage.removeItem("draftWord");
      localStorage.removeItem("editWord");
      localStorage.setItem("navigatebackfromcreateflow", this.location.path());
      localStorage.setItem(
        "navigatebackfromcreatebundle",
        this.location.path()
      );
      this.router.navigate(["/create-flow"]);
    }
  }

  userdashboard() {
    this.isuserdashboard = true;
    this.router.navigate(["/user-dashboard"]);
  }
  useranalytics() {
    this.isuserdashboard = true;
    this.router.navigate(["/content-analytics"]);
  }

  changepassword() {
    this.isuserdashboard = false;
    this.router.navigate(["/change-old-password"]);
  }

  //routerLink="/user-dashboard"

  avatarClicked(notification: any, source: any) {
    console.log(notification);
    if (notification.ContentType == "Flow") {
      localStorage.setItem("notificationflowId", notification.FlowId);
      localStorage.setItem("flow", null);
      this.router.navigate(["/view/" + notification.FlowId]);
    } else if (notification.ContentType == "Bundle") {
      this.router.navigate(["/viewbundle/" + notification.FlowId]);
    } else if (notification.ContentType == "User Following") {
      if (this.userData.emailAddress == notification.NotifierUserEmail) {
        //localStorage.setItem("LoggedInUser", notification.NotifierUserEmail);
        this.router.navigate(["/user-profile"]);
      } else {
        console.log(notification.NotifierUserEmail);
        //localStorage.setItem("otherUserEmailAddress", notification.NotifierUserEmail); 
        this.router.navigate(["/view-profile"]);
      } 
      var flowModel=new FlowQueryModel({
      userEmail:notification.NotifierUserEmail
      });
      this.dataService.flowByCategorieschangeMessage(notification);
    }
  }

  avatarProfileClicked(notification: any, source: any) {
    console.log(notification);
  
  if (notification.ContentType == "User Following") {
    if (this.userData.emailAddress == notification.NotifierUserEmail) {
      //localStorage.setItem("LoggedInUser", notification.NotifierUserEmail);
      this.router.navigate(["/user-profile"]);
    } else {
      console.log(notification.NotifierUserEmail);
      localStorage.setItem("LoggedInUser", notification.NotifierUserEmail); 
      this.router.navigate(["/view-profile"]);
    } 
      this.dataService.flowByCategorieschangeMessage(notification);
    }
  }

  aboutUsPage() {
    window.scroll(0, 0);
    this.router.navigate(["/aboutus"]);
  }

  privacyPolicy() {
    window.scroll(0, 0);
    this.router.navigate(["/privacypolicy"]);
  }

  terms() {
    window.scroll(0, 0);
    this.router.navigate(["/termsandconditions"]);
  }
  search() {
    this.router.navigate(["/home"]);
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  onActivate(event) {
    window.scroll(0, 0);
    document.body.scrollTop = 0;
    document.querySelector("body").scrollTo(0, 0);
  }

  onScroll() {
    //
    const tempNotificationLength = this.tempNotifications.length;

    if (
      this.infiniteScrollCount + this.tempNotifications.length <
      this.notificationsarray.length
    ) {
      if (tempNotificationLength < this.notificationsarray.length) {
        for (var i = 0; i < this.infiniteScrollCount; i++) {
          this.tempNotifications.push(
            this.notificationsarray[tempNotificationLength + i]
          );
        }
      }
    } else {
      var remainingNotificationCount =
        this.notificationsarray.length - this.tempNotifications.length;

      for (var i = 0; i < remainingNotificationCount; i++) {
        this.tempNotifications.push(
          this.notificationsarray[tempNotificationLength + i]
        );
      }
    }
  }

  generateQRCode() {
    if (this.qrcodename == "") {
      this.display = false;
      return;
    } else {
      this.value = this.qrcodename;
      this.display = true;
    }
  }
  downloadImage() {
    this.href = document.getElementsByTagName("img")[0].src;
  }

  createFlow() {
    localStorage.setItem("navigatebackfromcreateflow", this.location.path());
    localStorage.setItem("navigatebackfromcreatebundle", this.location.path());
    this.router.navigate(["/create-flow"]);
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.showToastForLogout();
    this.profilePicture = null;
    if (window.location.pathname == "/user-profile") {
      this.router.navigate(["/grid"]);
    } else {
      window.location.reload();
    }

    this.authService.logout();
  }

  getProfileUser(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
      this.noResultReturned = true;
      //
      this.showNotificationCount = false;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
            this.eventEmitterService.setProfilePictureEvent(
              this.profilePicture
            );
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.userDisplayName = this.uname = data.userData.userName;
          this.name = data.userData.firstName;
          this.isAdmin = data.userData.isAdmin;

          this.noResultReturned = false;
          // 
          // this.getnotification();
        });
    }
  }

  setallblue(selectedIcon) {
    this.homebgColor = "white";
    this.homeColor = "#212427";

    this.searchbgColor = "white";
    this.searchColor = "#212427";

    this.emoji_objectsbgColor = "white";
    this.emoji_objectsColor = "#212427";

    this.add_circle_outlinebgColor = "white";
    this.add_circle_outlineColor = "#212427";

    this.notificationsbgColor = "white";
    this.notificationsColor = "#212427";

    this.profilebgColor = "white";
    this.profileColor = "#212427";

    this.menubgColor = "white";
    this.menuColor = "#212427";

    this.account_circlebgColor = "white";
    this.account_circleColor = "#212427";

    this.personbgColor = "white";
    this.personColor = "#212427";

    this.setSelectedIconToBlue(selectedIcon);
  }

  setSelectedIconToBlue(selectedIcon) {
    switch (selectedIcon) {
      case "home":
        this.homebgColor = "white";
        this.homeColor = "#5ea1d8 !important";
        break;
      case "search":
        this.searchbgColor = "white";
        this.searchColor = "#5ea1d8 !important";
        this.search();
        break;
      case "emoji_objects":
        this.emoji_objectsbgColor = "white";
        this.emoji_objectsColor = "#5ea1d8 !important";
        this.router.navigate(["/bulbguide"]);
        break;
      case "add_circle_outline":
        this.add_circle_outlinebgColor = "white";
        this.add_circle_outlineColor = "#5ea1d8 !important";
        this.add_circle_outlineSize = "30px";
        break;
      case "notifications":
        this.notificationsbgColor = "white";
        this.notificationsColor = "#5ea1d8 !important";
        this.checkIsUserLoggedIn();
        break;

      case "profile":
        this.profilebgColor = "white";
        this.profileColor = "#5ea1d8 !important";
        this.profile_outlineSize = "30px";
        this.checkIsUserLoggedIn();
        break;

      case "menu":
        this.menubgColor = "white";
        this.menuColor = "#5ea1d8 !important";

        break;

      case "account":

        this.account_borderRadius = "50%";
        this.account_border = "3px solid #5ea1d8 !important";
        this.account_circlebgColor = "white";
        this.account_circleColor = "#5ea1d8 !important";


        break;
      case "person":
        this.personbgColor = "white";
        this.personColor = "#5ea1d8";

        break;
    }
  }

  openDialog() {
    if (this.userData != null) {
      localStorage.removeItem("ItemsRequired");
      localStorage.removeItem("flowCoverPageDetails");
      localStorage.removeItem("SupportingDocumentDocuments");
      localStorage.removeItem("SupportingDocumentHyperLinksRequired");
      localStorage.removeItem("stepsCoverPageDetails");
      localStorage.removeItem("draftWord");
      localStorage.removeItem("editWord");
      const dialogRef = this.dialog.open(BundledialogComponent, {
        panelClass: "dialoguebox",
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.eventEmitterService.setUserProfileCreateIconClickedEvent("");
        if (result === "bundle") {
          localStorage.setItem(
            "navigatebackfromcreatebundle",
            this.location.path()
          );
          localStorage.removeItem("createbundledetails");
          localStorage.removeItem("addbundleselectedflows");
          this.eventEmitterService.setUserProfileCreateIconClickedEvent("add_circle_outline");
          this.router.navigate(["/createbundle"]);
        } else if (result === "flow") {
          localStorage.setItem(
            "navigatebackfromcreateflow",
            this.location.path()
          );
          this.eventEmitterService.setUserProfileCreateIconClickedEvent("add_circle_outline");
          this.router.navigate(["/create-flow"]);
        } else {
          this.eventEmitterService.setUserProfileCreateIconClickedEvent("");
          window.location.reload();
        }
      });
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  iconClicked(icon) {
    this.setallblue(icon);
  }
  timeAgo(createdDate: string): string {
    const currentTime = new Date();
    const notificationTime = new Date(createdDate);
    const timeDifference = currentTime.getTime() - notificationTime.getTime(); // Difference in milliseconds
  
    // Convert time difference to different time units
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30); // Rough approximation of months
  
    if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    }
  }   
   parseCreatedDate = (createdDate) => {
    const currentDate = new Date();
    
    const [value, unit] = createdDate.split(" ");

    switch (unit) {
        case 'minutes':
        case 'minute':
            currentDate.setMinutes(currentDate.getMinutes() - parseInt(value));
            break;
        case 'hours':
        case 'hour':
            currentDate.setHours(currentDate.getHours() - parseInt(value));
            break;
        case 'days':
        case 'day':
            currentDate.setDate(currentDate.getDate() - parseInt(value));
            break;
        case 'weeks':
        case 'week':
            currentDate.setDate(currentDate.getDate() - (parseInt(value) * 7));
            break;
        default:
            console.log("Unhandled unit: " + unit);
    }

    return currentDate.toISOString(); // Returns timestamp in ISO format
}; 
  getnotification() {
    this.noResultReturned = true;
    //this.notificationsarray = [];
    //

    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      pageIndex: 0,
    });

    this.flowServiceService
      .getNotifications(flowQueryModel)
      .subscribe((data) => {
        this.isthereanyNotifications = true;
        this.noResultReturned = false;
        // 
        if (data != null) {
          data.forEach((element) => {
            var notification = {
              notification: {
                CreatedDate: this.timeAgo(element.createdDate),
                ProfilePicUrl: element.notifierProfilePicUrl,
                NotifierUserName: element.notifierUserName,
                NotificationSeen:element.notificationSeen,
                Message: element.message,
                FlowUrl: element.flowUrl,
                FlowId: element.notifierCustomerId, 
                ContentType: element.contentType,
                NotifierUserEmail: element.notifierUserEmail,
                VideoFlowPosterImageUrl: element.videoFlowPosterImageUrl,
                PartitionKey:element.partitionKey,
                RowKey:element.rowKey,
              },
            }; 
            this.notificationsarray.push(notification);
          });
          this.categorizeNotifications();
          this.notificationscount = this.notificationsarray.
          filter(notification => !notification.notification.NotificationSeen).length;
          if(this.notificationscount >0){
            this.showNotificationCount = true;
          }else{
            this.showNotificationCount = false;
          }
          console.log(this.notificationscount);
          console.log(this.notificationsarray);
 
        } else {
          this.isthereanyNotifications = false;
        }
      });  
  } 
  scheduleReadNotification(): void {
    // Use a delay of 1 minute 30 seconds (90,000 milliseconds) before calling ReadNotification
    setTimeout(() => {
      this.ReadNotification();
    }, 90000); // 90 seconds delay
  }
  
  categorizeNotifications() { 
    this.notificationsToday = this.notificationsarray.filter(notification =>
      this.isToday(this.parseRelativeTime(notification.notification.CreatedDate))
    );
    console.log(this.notificationsToday);
   
    this.notificationsYesterday = this.notificationsarray.filter(notification =>
      this.isYesterday(this.parseRelativeTime(notification.notification.CreatedDate))
    );
    console.log(this.notificationsYesterday);
   
    this.notificationsThisWeek = this.notificationsarray.filter(notification => {
      const notificationDate = this.parseRelativeTime(notification.notification.CreatedDate);
      return this.isThisWeek(notificationDate) && 
             !this.isToday(notificationDate) && 
             !this.isYesterday(notificationDate);
    });
    console.log(this.notificationsThisWeek);
   
    this.notificationsOlder = this.notificationsarray.filter(notification => {
      const notificationDate = this.parseRelativeTime(notification.notification.CreatedDate);
      return this.isOlder(notificationDate) && !this.isThisWeek(notificationDate);
    });
    console.log(this.notificationsOlder);
  }
  

  parseRelativeTime(relativeTime: string): Date {
    const time = new Date();
    const numberPattern = /\d+/g;
    const value = parseInt(relativeTime.match(numberPattern)?.[0] || "0", 10);
    if (relativeTime.includes("hour")) {
      time.setHours(time.getHours() - value);
    } else if (relativeTime.includes("minute")) {
      time.setMinutes(time.getMinutes() - value);
    } else if (relativeTime.includes("day")) {
      time.setDate(time.getDate() - value);
    } else if (relativeTime.includes("week")) {
      time.setDate(time.getDate() - value * 7);
    }
    return time;
  }

  isToday(date: Date): boolean {
    return date.toDateString() === this.today.toDateString();
  }

  isYesterday(date: Date): boolean {
    return date.toDateString() === this.yesterday.toDateString();
  }

  isThisWeek(date: Date): boolean {
    return date > this.thisWeekStart && date <= this.today;
  }

  isOlder(date: Date): boolean {
    return date < this.thisWeekStart;
  }
  ReadNotification():void {
    
const transformedNotifications = this.notificationsarray.map(item => {
  const notification = item.notification;
  return {
      ...notification,
      CreatedDate: this.parseCreatedDate(notification.CreatedDate)
  };
}); 
    console.log(transformedNotifications); 
    this.noResultReturned = true;  
    console.log(this.noResultReturned);
    console.log(this.notificationsarray);
    this.flowServiceService
      .MarkAsReadNotifications(transformedNotifications)
      .subscribe((data) => {
        this.isthereanyNotifications = true;
        this.noResultReturned = false;
        // 
        if (data != null) {
          console.log("success")
          // data.forEach((element) => {
          //   var notification = {
          //     notification: {
          //       CreatedDate: element.createdDate,
          //       NotifierProfilePicUrl: element.notifierProfilePicUrl,
          //       NotifierUserName: element.notifierUserName,
          //       Message: element.message,
          //       FlowUrl: element.flowUrl,
          //       NotifierCustomerId: element.notifierCustomerId, 
          //       ContentType: element.contentType,
          //       NotifierUserEmail: element.notifierUserEmail,
          //       VideoFlowPosterImageUrl: element.videoFlowPosterImageUrl,
          //       PartitionKey:element.partitionKey,
          //       RowKey:element.rowKey,
          //     },
          //   };
          //   if(!element.NotificationSeen){ 
          //     this.notificationsarray.push(notification);
          //   }
         // });
          // this.notificationscount = this.notificationsarray.
          // filter(notification => !notification.notification.NotificationSeen).length; 
          // console.log(this.notificationscount);
          // console.log(this.notificationsarray);
        
          // if(this.notificationscount >0){
          //   this.showNotificationCount = true;
          // }else{
          //   this.showNotificationCount = false;
          // }
        } else {
          this.isthereanyNotifications = false;
        }
      });
  }
}
