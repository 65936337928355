import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-donation-popup',
  templateUrl: './donation-popup.component.html',
  styleUrls: ['./donation-popup.component.scss']
})
export class DonationPopupComponent {
  donationAmount: number = 0;

  constructor(private toastr:ToastrService,
    public dialogRef: MatDialogRef<DonationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    console.log(this.donationAmount)
    // if(this.donationAmount > 0){
    //   this.toastr.warning("Gift amount can not be 0");
    // }else{ 
      this.dialogRef.close(this.donationAmount);
    // }
  }
}
