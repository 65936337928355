import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/Services/dataservice.service";
import { EventEmitterService } from "src/app/Services/event-emitter.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { UserserviceService } from "src/app/Services/userservice.service";

@Component({
  selector: "app-in-appropriate",
  templateUrl: "./in-appropriate.component.html",
  styleUrls: ["./in-appropriate.component.scss"],
})
export class InAppropriateComponent implements OnInit {
  message: any;
  otherMessage: any;
  title: string;
  reasons: any;
  reasonsToSubmit: any;
  sexual: boolean;
  graphic: boolean;
  hateful: boolean;
  harmful: boolean;
  rating: boolean;
  illegal: boolean;
  copycat: boolean;
  other: boolean;
  reason: string;
  flowData: any;
  userData: any;
  imageSource: any;
  description: any;
  nextCount: number;
  itemsRequired: number;
  stepCount: number;
  progressPercentage: number;
  progressBy: number;
  dataSource = [];
  dataSourceSteps = [];
  showimage: boolean;
  flowCoverImageUrl: any;

  constructor(
    private flowServiceService: FlowServiceService,
    private router: Router,
    private dataService: DataService,
    
    public formbuilder: UntypedFormBuilder,
    private userserviceService: UserserviceService,
    private eventEmitterService: EventEmitterService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InAppropriateComponent>
  ) {
    this.reasonsToSubmit = [];
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.dataService.currentMessage.subscribe((flowData) => {
      this.flowData = flowData;
      this.imageSource = this.flowData.url;
      this.description = this.flowData.description;

      if (this.flowData === "default message") {
        this.flowData = JSON.parse(localStorage.getItem("flow"));

      }
    });

    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    if (this.userData == null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
    this.flowCoverImageUrl = "url(" + '"' + this.flowData.url + '"' + ")";
  }

  selectedRadio: string; 

  onRadioChange(value: string) {
    this.selectedRadio = value;
  }
  showTextArea: boolean = false;

  // Method to handle reason selection changes
  onReasonChange(selectedReason: string): void {
    console.log( selectedReason);
    if(selectedReason ==='copycat' || selectedReason === 'other')
    {
      this.showTextArea = true;
    }else{
      this.showTextArea = false;
    } 
    console.log( this.showTextArea);
  }
  submit() {

    
    if (this.reason == "sexual") {
      this.reasonsToSubmit.push("Sexual content");
    }
    if (this.reason == "graphic") {
      this.reasonsToSubmit.push("Graphic violence");
    }
    if (this.reason == "hateful") {
      this.reasonsToSubmit.push("Hateful or abusive content");
    }
    if (this.reason == "rating") {
      this.reasonsToSubmit.push("Improper content rating");
    }
    if (this.reason == "illegal") {
      this.reasonsToSubmit.push("Illegal prescription or other drug");
    }
    if (this.reason == "copycat") {
      this.reasonsToSubmit.push("Copycat or impresonation");
    }
    if (this.reason == "other") {
      this.reasonsToSubmit.push("Other objection");
    }

    var flowModel = {
      ReportedByUserEmail : this.userData.emailAddress,
      FlowId: this.flowData.rowKey,
      Reasons: this.reasonsToSubmit[0],
      Message: this.message,
      OtherMessage: this.otherMessage
    };
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    if (this.userData != null) {
      this.flowServiceService
        .reportFlow(flowModel)
        .subscribe((data) => {
          
          if (data !=null) {
            localStorage.setItem("notificationflowId", null);
            this.toastr.success("Flow reported successfully");
            this.router.navigate(["/grid"]);
            this.dialogRef.close();
          }
        });
    }

  }

  close() {
    this.dialogRef.close();
  }


}
